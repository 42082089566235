
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			:heading="page_title"
			:breadcrumbs="breadcrumbs"
			:before-tabs="true"
		>
			<template
				v-if="show_entry_actions"
				#actions
			>
				<ButtonDropdownComponent
					label="Actions"
				>
					<ButtonComponent
						label="Duplicate activity"
						type="dropdown-item"
						icon="duplicate"
						@click="goToActivityDuplicate"
					/>
					<ButtonComponent
						label="Cancel activity"
						type="dropdown-item"
						icon="delete"
						@click="show_cancel_modal = true"
					/>
				</ButtonDropdownComponent>
				<ButtonComponent
					label="Export all attendees"
					icon="download"
					@click="exportData"
				/>
			</template>
		</HeaderComponent>
		<TabsComponent
			v-if="content_ready"
			ref="tabs"
			:tabs="tabs"
		>
			<template #attendees>
				<div class="page-content page-content--table">
					<div class="filters-and-search">
						<div class="filters-and-search__filters">
							<div class="filters-and-search__dropdowns">
								<template
									v-if="attendees_selected_indexes.length === 0"
								>
									<SearchUserComponent
										v-model="attendees_searched_member_id"
										input-id="attendees_search_members"
										placeholder="Search for a member"
									/>
									<ButtonComponent
										v-if="attendees_searched_member_id !== null"
										label="Add attendee"
										type="invert"
										icon="add"
										@click="addSearchedAttendeeToActivity"
									/>
								</template>
								<template
									v-if="attendees_selected_indexes.length > 0"
								>
									<ButtonComponent
										label="Mark as attended"
										type="invert"
										icon="check"
										@click="markSelectedAttendeesAsAttended"
									/>
								</template>
								<template
									v-if="attendees_selected_indexes.length > 0 && show_induction_related_actions"
								>
									<ButtonComponent
										label="Mark as inducted"
										type="invert"
										icon="check"
										@click="markSelectedAttendeesAsInducted"
									/>
								</template>
								<template
									v-if="attendees_selected_indexes.length > 0 && show_training_related_actions"
								>
									<ButtonComponent
										label="Mark as trained"
										type="invert"
										icon="check"
										@click="show_train_attendees_modal = true"
									/>
								</template>
								<template
									v-if="attendees_selected_indexes.length > 0"
								>
									<ButtonDropdownComponent
										label="More actions"
										type="invert"
									>
										<ButtonComponent
											label="Mark as not attended"
											type="dropdown-item"
											icon="close"
											@click="markSelectedAttendeesAsNotAttended"
										/>
										<ButtonComponent
											label="Remove from attendee list"
											type="dropdown-item"
											icon="delete"
											@click="show_remove_modal = true"
										/>
										<ButtonComponent
											label="Warn attendees"
											type="dropdown-item"
											icon="incident"
											@click="show_warn_modal = true"
										/>
										<ButtonComponent
											label="Contact attendees"
											type="dropdown-item"
											icon="envelope"
											@click="show_contact_attendees_modal = true"
										/>
									</ButtonDropdownComponent>
								</template>
							</div>
						</div>
					</div>
					<PaginatedTableComponent
						v-model:selectedIndexes="attendees_selected_indexes"
						id-prefix="paginated-table-attendees"
						:columns="attendee_table_columns"
						:data="attendee_data"
						:active-filters="active_filters_attendee"
						:selectable="true"
						@go-to-member="row => goToMember( row )"
						@remove-attendee-booking="removeAttendeeBooking"
					/>
				</div>
			</template>
			<template #waiting_list>
				<div class="page-content page-content--table">
					<div class="filters-and-search">
						<div class="filters-and-search__filters">
							<div class="filters-and-search__dropdowns">
								<template
									v-if="waiting_list_selected_indexes.length === 0"
								>
									<SearchUserComponent
										v-model="waiting_list_searched_member_id"
										input-id="waiting_list_search_members"
										placeholder="Search for a member"
									/>
									<ButtonComponent
										v-if="waiting_list_searched_member_id !== null"
										label="Add to waiting list"
										type="invert"
										icon="add"
										@click="addSearchedAttendeeToWaitingList"
									/>
								</template>
								<template
									v-if="waiting_list_selected_indexes.length > 0"
								>
									<ButtonComponent
										label="Contact waiting list"
										type="invert"
										icon="envelope"
										@click="show_contact_waitlist_modal = true"
									/>
								</template>
							</div>
						</div>
					</div>
					<PaginatedTableComponent
						v-model:selectedIndexes="waiting_list_selected_indexes"
						id-prefix="paginated-table-waiting-list"
						:columns="waiting_list_table_columns"
						:data="waiting_list_data"
						:active-filters="active_filters_waiting_list"
						:selectable="true"
						@go-to-member="row => goToMember( row )"
						@convert-to-attendee="convertWaitlistBookingToRegular"
						@remove-waiting-list-booking="removeWaitlistBooking"
					/>
				</div>
			</template>
			<template #activity_details>
				<div class="page-content page-content--form">
					<div class="flex flex--wrap grid grid--small">
						<div class="grid__item a12-12">
							<InputComponent
								v-model="model_data.title"
								label="Title"
								type="text"
								input-id="title"
								:errors="model_errors.title"
							/>
						</div>
						<div class="grid__item a12-12">
							<InputComponent
								v-model="model_data.activity_type"
								label="Activity type"
								type="select"
								input-id="activity_type"
								:options="activity_types"
								:errors="model_errors.activity_type"
							/>
						</div>
						<div
							v-if="!activity_is_legacy"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.listing_description"
								label="Listing description"
								type="text"
								input-id="listing_description"
								:errors="model_errors.listing_description"
							/>
						</div>
						<div
							v-if="!activity_is_legacy_website"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.description"
								label="Description"
								type="textarea"
								input-id="description"
								:rows="10"
								:errors="model_errors.description"
								:show-textarea-formatting-guide="true"
							/>
						</div>
						<div
							v-if="activity_is_legacy_website"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.description_rich_text"
								label="Description (Rich Text)"
								type="textarea"
								input-id="description_rich_text"
								:rows="10"
								:errors="model_errors.description_rich_text"
							/>
						</div>
						<div
							v-if="!activity_is_legacy && !activity_is_legacy_website"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.what_to_expect"
								sub-label="This information will appear in attendee’s booking confirmation emails."
								label="What to expect"
								type="textarea"
								input-id="what_to_expect"
								:rows="10"
								:errors="model_errors.what_to_expect"
								:show-textarea-formatting-guide="true"
							/>
						</div>
						<div
							v-if="!activity_is_legacy"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.activity_image"
								input-id="activity_image"
								label="Activity image"
								sub-label="JPEG or PNG - max 1GB"
								type="file"
								accepted-file-types="image/jpeg, image/png"
								:accepted-asset-kinds="['image']"
								:errors="model_errors.activity_image"
							/>
						</div>
						<div
							v-if="!activity_is_legacy_website"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.attendee_information"
								sub-label="This information will appear in attendee’s booking confirmation emails."
								label="Attendee information"
								type="textarea"
								input-id="attendee_information"
								:rows="10"
								:errors="model_errors.attendee_information"
								:show-textarea-formatting-guide="true"
							/>
						</div>
						<div
							v-if="!activity_is_legacy && !activity_is_legacy_website"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.access_requirements"
								sub-label="This information will appear in attendee’s booking confirmation emails. Leave blank to use the default requirements text instead."
								label="Access requirements"
								type="textarea"
								input-id="access_requirements"
								:rows="10"
								:errors="model_errors.access_requirements"
								:show-textarea-formatting-guide="true"
							/>
						</div>
						<div
							v-if="!activity_is_legacy_website"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.activity_lead"
								label="Activity lead"
								type="search_user"
								input-id="activity_lead"
								autocomplete="off"
								:search-user-managers-only="true"
								:errors="model_errors.activity_lead"
							/>
						</div>
						<template v-if="show_public_event_related_actions">
							<div class="grid__item a6-12">
								<InputComponent
									v-model="model_data.booking_link_url"
									label="Booking link URL"
									sub-label="Optional"
									type="text"
									input-id="booking_link_url"
									:errors="model_errors.booking_link_url"
								/>
							</div>
							<div class="grid__item a6-12">
								<InputComponent
									v-model="model_data.booking_link_text"
									label="Booking link text"
									sub-label="Optional"
									type="text"
									input-id="booking_link_text"
									:errors="model_errors.booking_link_text"
								/>
							</div>
						</template>
						<div class="grid__item a12-12">
							<div class="flex flex--wrap flex--align-end grid grid--small">
								<div class="grid__item a6-12">
									<InputComponent
										v-model="model_data.start_date"
										label="Start date"
										type="date"
										input-id="start_date"
										:errors="model_errors.start_date"
									/>
								</div>
								<div
									v-if="!activity_is_legacy"
									class="grid__item a6-12"
								>
									<InputComponent
										v-model="model_data.end_date"
										label="End date"
										sub-label="Optional"
										type="date"
										input-id="end_date"
										:errors="model_errors.end_date"
									/>
								</div>
							</div>
						</div>
						<div class="grid__item a12-12">
							<div class="flex flex--wrap grid grid--small">
								<div class="grid__item a6-12">
									<InputComponent
										v-model="model_data.start_time"
										label="Start time"
										type="time"
										input-id="start_time"
										:errors="model_errors.start_time"
									/>
								</div>
								<div class="grid__item a6-12">
									<InputComponent
										v-model="model_data.end_time"
										label="End time"
										type="time"
										input-id="end_time"
										:errors="model_errors.end_time"
									/>
								</div>
							</div>
						</div>
						<div
							v-if="show_booking_related_actions"
							class="grid__item a12-12"
						>
							<div class="flex flex--wrap grid grid--small">
								<div class="grid__item a6-12">
									<InputComponent
										v-model="model_data.bookings_available_from_date"
										label="Bookings available from date"
										type="date"
										input-id="bookings_available_from_date"
										:errors="model_errors.bookings_available_from"
									/>
								</div>
								<div class="grid__item a6-12">
									<InputComponent
										v-model="model_data.bookings_available_from_time"
										label="Bookings available from time"
										type="time"
										input-id="bookings_available_from_time"
									/>
								</div>
							</div>
						</div>
						<div
							v-if="show_booking_related_actions"
							class="grid__item a6-12"
						>
							<InputComponent
								v-model="model_data.capacity"
								label="Capacity"
								type="number"
								input-id="capacity"
								:errors="model_errors.capacity"
							/>
						</div>
						<div
							v-if="show_booking_related_actions"
							class="grid__item a6-12"
						>
							<InputComponent
								v-model="model_data.waiting_list_capacity"
								label="Waiting list capacity"
								type="number"
								input-id="waiting_list_capacity"
								:errors="model_errors.waiting_list_capacity"
							/>
						</div>
						<div
							v-if="!activity_is_legacy"
							class="grid__item a6-12"
						>
							<InputComponent
								v-model="model_data.location"
								label="Location"
								type="select"
								input-id="location"
								:options="locations"
								:errors="model_errors.location"
							/>
						</div>
						<div
							v-if="show_tool_related_actions && tool_instances_are_retrievable"
							class="grid__item a12-12"
						>
							<InputComponent
								v-model="model_data.tool_instances"
								label="Search and assign tools to this activity"
								type="search_tool_instances"
								input-id="tool_instances"
								:tool-instance-options="available_tool_instances"
								:errors="model_errors.tool_instances"
								:loading="loading_tool_instances"
							/>
						</div>
					</div>

					<div class="flex flex--wrap grid grid--small">
						<div class="grid__item">
							<ButtonComponent
								label="Save changes"
								@click="saveActivityData"
							/>
						</div>
						<div class="grid__item">
							<ButtonComponent
								label="Cancel"
								type="outline"
								@click="$router.push( { name: 'bookings__all_activities' } )"
							/>
						</div>
					</div>
				</div>
			</template>
		</TabsComponent>
	</LoadingStateComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Mark attendees as trained"
		:show="show_train_attendees_modal"
		@close-modal="show_train_attendees_modal = false"
	>
		<div class="specs-list">
			<dl class="specs-list__list">
				<div class="specs-list__list-item">
					<dt>
						Activity name:
					</dt>
					<dd>
						{{ model_data.title }}
					</dd>
				</div>
			</dl>
		</div>
		<div class="form__set">
			<p class="p">
				<strong>You are marking the following attendees…</strong>
			</p>
			<ul class="ul">
				<li
					v-for="attendee, index in getSelectedAttendeeData()"
					:key="index"
				>
					{{ attendee[0].visible }}
				</li>
			</ul>
		</div>
		<InputComponent
			v-model="activity_training_tool_classes"
			label="…as trained for:"
			type="checkboxgroup"
			input-id="activity_tool_class_options"
			:options="activity_tool_class_options"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Mark as trained"
				@click="markSelectedAttendeesAsTrained()"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_train_attendees_modal = false"
			/>
		</div>
	</ModalComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Remove attendees"
		:show="show_remove_modal"
		@close-modal="show_remove_modal = false"
	>
		<p class="p">
			Are you sure you want to remove the following member(s)? After confirming below the data for this member will be removed from this activity and the action cannot be undone.
		</p>
		<div class="form__set">
			<p class="p">
				<strong>You are removing the following attendees:</strong>
			</p>
			<ul class="ul">
				<li
					v-for="attendee, index in getSelectedAttendeeData()"
					:key="index"
				>
					{{ attendee[0].visible }}
				</li>
			</ul>
		</div>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Remove attendees"
				type="danger"
				@click="removeSelectedAttendeeBookings()"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_remove_modal = false"
			/>
		</div>
	</ModalComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Warn attendees"
		:show="show_warn_modal"
		@close-modal="show_warn_modal = false"
	>
		<div class="specs-list">
			<dl class="specs-list__list">
				<div class="specs-list__list-item">
					<dt>
						Activity name:
					</dt>
					<dd>
						{{ model_data.title }}
					</dd>
				</div>
				<div class="specs-list__list-item">
					<dt>
						Date:
					</dt>
					<dd>
						{{ human_activity_date }}
					</dd>
				</div>
				<div class="specs-list__list-item">
					<dt>
						Time:
					</dt>
					<dd>
						{{ human_activity_time }}
					</dd>
				</div>
			</dl>
		</div>
		<div class="form__set">
			<p class="p">
				<strong>You are issuing a warning to the following attendees:</strong>
			</p>
			<ul class="ul">
				<li
					v-for="attendee, index in getSelectedAttendeeData()"
					:key="index"
				>
					{{ attendee[0].visible }}
				</li>
			</ul>
		</div>
		<InputComponent
			v-model="mass_warning_reason"
			input-id="mass_warning_reason"
			label="Reason for warning"
			sub-label="Please remember to specify the section of the member agreement which has been violated by the member."
			type="textarea"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Issue warning(s)"
				type="danger"
				@click="issueWarningsToSelectedAttendees()"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_warn_modal = false"
			/>
		</div>
	</ModalComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Contact attendees"
		:show="show_contact_attendees_modal"
		@close-modal="show_contact_attendees_modal = false"
	>
		<div class="specs-list">
			<dl class="specs-list__list">
				<div class="specs-list__list-item">
					<dt>
						Activity name:
					</dt>
					<dd>
						{{ model_data.title }}
					</dd>
				</div>
				<div class="specs-list__list-item">
					<dt>
						Date:
					</dt>
					<dd>
						{{ human_activity_date }}
					</dd>
				</div>
				<div class="specs-list__list-item">
					<dt>
						Time:
					</dt>
					<dd>
						{{ human_activity_time }}
					</dd>
				</div>
			</dl>
		</div>
		<div class="form__set">
			<p class="p">
				<strong>You are contacting the following members:</strong>
			</p>
			<ul class="ul">
				<li
					v-for="attendee, index in getSelectedAttendeeData()"
					:key="index"
				>
					{{ attendee[0].visible }}
				</li>
			</ul>
		</div>
		<InputComponent
			v-model="mass_contact_message"
			input-id="mass_contact_message"
			label="Email note"
			type="textarea"
			:show-textarea-formatting-guide="true"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Send message(s)"
				@click="contactSelectedAttendees()"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_contact_attendees_modal = false"
			/>
		</div>
	</ModalComponent>
	<ModalComponent
		v-if="show_entry_actions"
		heading="Contact waiting list"
		:show="show_contact_waitlist_modal"
		@close-modal="show_contact_waitlist_modal = false"
	>
		<div class="form__set">
			<p class="p">
				<strong>You are contacting the following members:</strong>
			</p>
			<ul class="ul">
				<li
					v-for="attendee, index in getSelectedWaitlistData()"
					:key="index"
				>
					{{ attendee[0].visible }}
				</li>
			</ul>
		</div>
		<InputComponent
			v-model="mass_contact_message"
			input-id="mass_contact_message"
			label="Email note"
			type="textarea"
			:show-textarea-formatting-guide="true"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Send message(s)"
				@click="contactSelectedWaitlist()"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				@click="show_contact_waitlist_modal = false"
			/>
		</div>
	</ModalComponent>
	<ModalComponent
		heading="Cancel activity"
		:show="show_cancel_modal"
		@close-modal="show_cancel_modal = false"
	>
		<p class="p">
			Are you sure you want to cancel this activity? After confirming below the data for this activity will be permanently removed and this action cannot be undone.
		</p>
		<InputComponent
			v-model="cancel_activity_notify_attendees"
			label="Notify all attendees and waiting list members"
			type="checkbox"
			input-id="cancel_activity_notify_attendees"
		/>
		<InputComponent
			v-if="cancel_activity_notify_attendees"
			v-model="cancel_activity_cancellation_details"
			label="Cancellation details"
			sub-label="Optional"
			type="textarea"
			:rows="4"
			input-id="cancel_activity_cancellation_details"
			:show-textarea-formatting-guide="true"
		/>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Confirm cancellation"
				type="danger"
				:padded="true"
				@click="cancelActivity"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_cancel_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonDropdownComponent from '../../components/ButtonDropdownComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';
import TabsComponent from '../../components/TabsComponent.vue';
import PaginatedTableComponent from '../../components/PaginatedTableComponent.vue';
import SearchUserComponent from '../../components/SearchUserComponent.vue';

import gql_query_activity from '../../graphql/query/Activity.gql';
import gql_query_all_locations from '../../graphql/query/AllLocations.gql';
import gql_query_bookable_tool_instances_by_date_range from '../../graphql/query/BookableToolInstancesByDateRange.gql';
import gql_query_tools_by_id from '../../graphql/query/ToolsById.gql';

import {
	formatDate,
	formatDateHuman,
	formatTime,
	scrollFirstErrorIntoView,
	convertCraftEntriesToSelectOptions,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	CONTENT_MODE_ADD,
	CONTENT_MODE_EDIT,
	CONTENT_MODE_DUPLICATE,
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_ACTION_LINK,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	ACTIVITY_BOOKING_TYPE_REGULAR,
	ACTIVITY_BOOKING_TYPE_WAITLIST,
	ACTIVITY_TYPE_MEMBER_EVENT,
	ACTIVITY_TYPE_OPEN_EVENT,
	ACTIVITY_TYPE_PUBLIC_EVENT,
	ACTIVITY_TYPE_TRAINING,
	ACTIVITY_TYPE_NEW_MEMBER_INDUCTION,
	ACTIVITY_TYPE_LEGACY_EVENT,
	ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE,
	ACTIVITY_ATTENDANCE_STATUS_ATTENDED,
	ACTIVITY_ATTENDANCE_STATUS_DID_NOT_ATTEND,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		InputComponent,
		ButtonDropdownComponent,
		ButtonComponent,
		HeaderComponent,
		ModalComponent,
		TabsComponent,
		PaginatedTableComponent,
		SearchUserComponent,
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,

			// We must clear tool instances if date fields change (because they
			// might not be available anymore) but deep watchers can't get field
			// changes in the watcher handler - instead my solution is to build
			// a string holding all the temporal data and compare it inside the
			// watcher to see whether we need to reload the tool instances or
			// not. Faff!
			temporal_string: '',

			loading_tool_instances: false,

			attendee_data: [],
			attendee_table_columns: [
				{
					label: 'Name',
					type: PAGINATED_TABLE_COLUMN_ACTION_LINK,
					action_event: 'go-to-member',
				},
				{
					label: 'Attended',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: 'Inducted',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Remove',
					button_variant: 'danger',
					action_event: 'remove-attendee-booking',
				},
			],
			attendees_selected_indexes: [],
			attendees_searched_member_id: null,

			waiting_list_data: [],
			waiting_list_table_columns: [
				{
					label: 'Name',
					type: PAGINATED_TABLE_COLUMN_ACTION_LINK,
					action_event: 'go-to-member',
				},
				{
					label: 'Inducted',
					type: PAGINATED_TABLE_COLUMN_TEXT,
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Convert to booking',
					action_event: 'convert-to-attendee',
				},
				{
					label: '',
					type: PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
					button_label: 'Remove',
					button_variant: 'danger',
					action_event: 'remove-waiting-list-booking',
				},
			],
			waiting_list_selected_indexes: [],
			waiting_list_searched_member_id: null,

			model_data: {},
			model_errors: {},

			available_tool_instances: [],

			activity_tool_class_options: [],
			activity_training_tool_classes: [],

			mass_warning_reason: '',
			mass_contact_message: '',

			cancel_activity_notify_attendees: false,
			cancel_activity_cancellation_details: '',

			show_train_attendees_modal: false,
			show_contact_attendees_modal: false,
			show_contact_waitlist_modal: false,
			show_warn_modal: false,
			show_remove_modal: false,
			show_cancel_modal: false,

			activity_types: [
				{ value: ACTIVITY_TYPE_MEMBER_EVENT, label: 'Member event' },
				{ value: ACTIVITY_TYPE_OPEN_EVENT, label: 'UCL event' },
				{ value: ACTIVITY_TYPE_PUBLIC_EVENT, label: 'Public event' },
				{ value: ACTIVITY_TYPE_TRAINING, label: 'Training' },
				{ value: ACTIVITY_TYPE_NEW_MEMBER_INDUCTION, label: 'New member induction' },
				{ value: ACTIVITY_TYPE_LEGACY_EVENT, label: 'Legacy event', disabled: true },
				{ value: ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE, label: 'Legacy event (website)', disabled: true },
			],
			locations: [],
		};
	},
	computed: {
		tabs() {
			if (
				this.content_mode !== CONTENT_MODE_EDIT
				|| this.model_data.activity_type === ACTIVITY_TYPE_PUBLIC_EVENT
				|| this.model_data.activity_type === ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE
			) {
				return [
					{ name: 'activity_details', label: 'Activity details' },
				];
			}
			return [
				{ name: 'attendees', label: 'Attendees', subLabel: `(${this.attendee_data.length})` },
				{ name: 'waiting_list', label: 'Waiting list', subLabel: `(${this.waiting_list_data.length})` },
				{ name: 'activity_details', label: 'Activity details' },
			];
		},
		page_title() {
			if (
				this.content_mode === CONTENT_MODE_ADD
				&& (
					Object.keys( this.model_data ).length === 0
					|| this.model_data.title === undefined
					|| this.model_data.title === ''
				)
			) {
				return 'New activity';
			}
			if ( this.content_mode === CONTENT_MODE_DUPLICATE ) {
				return this.model_data.title + ' (duplicating)';
			}
			return this.model_data.title;
		},
		breadcrumbs() {
			if ( this.loading ) {
				return [];
			}
			return [
				{ label: 'Bookings' },
				{ label: 'Activities', route: 'bookings__all_activities' },
				{ label: this.page_title },
			];
		},
		content_mode() {
			switch ( this.$route.name ) {
				case 'bookings__add_activity':
					return CONTENT_MODE_ADD;
				case 'bookings__duplicate_activity':
					return CONTENT_MODE_DUPLICATE;
				default:
					return CONTENT_MODE_EDIT;
			}
		},
		show_entry_actions() {
			return this.content_mode === CONTENT_MODE_EDIT;
		},
		show_booking_related_actions() {
			return this.model_data.activity_type !== ACTIVITY_TYPE_PUBLIC_EVENT
				&& this.model_data.activity_type !== ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE
			;
		},
		show_tool_related_actions() {
			return this.model_data.activity_type !== ACTIVITY_TYPE_PUBLIC_EVENT
				&& this.model_data.activity_type !== ACTIVITY_TYPE_NEW_MEMBER_INDUCTION
				&& this.model_data.activity_type !== ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE
			;
		},
		show_induction_related_actions() {
			return this.model_data.activity_type === ACTIVITY_TYPE_NEW_MEMBER_INDUCTION;
		},
		show_training_related_actions() {
			return this.model_data.activity_type === ACTIVITY_TYPE_TRAINING;
		},
		show_public_event_related_actions() {
			return this.model_data.activity_type === ACTIVITY_TYPE_PUBLIC_EVENT;
		},
		activity_is_legacy() {
			return this.model_data.activity_type === ACTIVITY_TYPE_LEGACY_EVENT;
		},
		activity_is_legacy_website() {
			return this.model_data.activity_type === ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE;
		},
		content_ready() {
			if ( this.content_mode === CONTENT_MODE_ADD ) {
				return true;
			}
			return Object.keys( this.model_data ).length > 0;
		},
		tool_instances_are_retrievable() {
			return ( this.model_data.activity_type === ACTIVITY_TYPE_LEGACY_EVENT || this.model_data.location )
				&& this.model_data.start_date
				&& this.model_data.start_date.length > 0
				&& this.model_data.start_time
				&& this.model_data.end_time;
		},
		human_activity_date() {
			if ( this.model_data.start_date && this.model_data.start_date.length > 0 ) {
				if ( this.model_data.end_date && this.model_data.end_date.length > 0 ) {
					return formatDateHuman( new Date( this.model_data.start_date[0] ) )
					+ ' — '
					+ formatDateHuman( new Date( this.model_data.end_date[0] ) );
				}
				return formatDateHuman( new Date( this.model_data.start_date[0] ) );
			}
			return '';
		},
		human_activity_time() {
			if ( this.model_data.start_time && this.model_data.end_time ) {
				return this.model_data.start_time
					+ ' — '
					+ this.model_data.end_time;
			}
			return '';
		},
	},
	watch: {
		// Note - initially there were 5 separate watchers for each temporal
		// field instead of this one, but it meant 5 API requests were sent off
		// every time the model data was loaded in, which was painful and wrong.
		// This method is also painful, but I believe less wrong.
		model_data: {
			handler( new_data ) {
				if ( this.getTemporalString( new_data ) !== this.temporal_string ) {
					this.refreshToolInstances();
				}
			},
			deep: true,
		},
		'model_data.tool_instances'() {
			this.refreshToolClasses();
		},
	},
	mounted() {
		this.$craftGraphqlApiClient.query( gql_query_all_locations )
			.then( ( response ) => {
				this.locations = convertCraftEntriesToSelectOptions(
					response.data.entries
				);
			} );
		if ( this.content_mode === CONTENT_MODE_ADD ) {
			this.loading_state = LOADING_STATE_NONE;
			return;
		}
		this.refreshActivityData();
	},
	methods: {
		convertAttendeeData( data ) {
			return data.map( member => {
				return [
					{
						visible: member.fullName,
						member_id: member.id,
					},
					{
						visible: (
							member.attendedActivity === ACTIVITY_ATTENDANCE_STATUS_ATTENDED
								? 'Yes'
								: (
									member.attendedActivity === ACTIVITY_ATTENDANCE_STATUS_DID_NOT_ATTEND
										? 'No'
										: 'Pending'
								)
						)
					},
					{
						visible: member.inductions.length > 0 ? 'Yes' : 'No',
					},
					{
						visible: null,
					}
				];
			} );
		},
		convertWaitingListData( data ) {
			return data.map( member => {
				return [
					{
						visible: member.fullName,
						member_id: member.id,
					},
					{
						visible: member.inductions.length > 0 ? 'Yes' : 'No',
					},
					{
						visible: null,
					},
					{
						visible: null,
					}
				];
			} );
		},
		convertActivityData( data ) {
			//data.dateCreated = new Date( data.dateCreated );
			data.activity_type = data.typeHandle;
			data.activity_image = data.activity_image && data.activity_image.length ? data.activity_image.map( asset => asset.id ) : [];
			data.activity_lead = data.activity_lead && data.activity_lead.length ? data.activity_lead[0].id : '';
			const start_date = new Date( data.start_date );
			data.start_date = [formatDate( start_date )];
			if ( data.end_date ) {
				const end_date = new Date( data.end_date );
				data.end_date = [formatDate( end_date )];
			}
			data.start_time = formatTime( new Date( data.start_time ) );
			data.end_time = formatTime( new Date( data.end_time ) );
			data.bookings_available_from_date = [formatDate( new Date( data.bookings_available_from ) )];
			data.bookings_available_from_time = formatTime( new Date( data.bookings_available_from ) );
			data.location = data.location && data.location.length ? data.location[data.location.length - 1].id : '';
			data.tool_instances = data.tool_instances ? data.tool_instances.map( tool => tool.id ) : [];
			data.attendees = null; // Unset this, we handle it elsewhere.
			data.waiting_list = null; // Unset this, we handle it elsewhere.
			return data;
		},
		async saveActivityData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};

			this.$craftActionApiClient.query(
				'entries/save-entry',
				{
					// To add or duplicate something, we just submit the edit
					// form without an ID.
					entryId: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					sectionId: this.$craftSectionData.getSectionId( 'activities' ),
					// The saveEntry controller will fatally error if we supply a null typeId, so we need to pick the first one if it's blank.
					typeId: this.model_data.activity_type ? this.$craftSectionData.getEntryTypeId( 'activities', this.model_data.activity_type ) : this.$craftSectionData.getEntryTypeId( 'activities', this.activity_types[0].value ),
					title: this.model_data.title,
					fields: {
						listing_description: this.model_data.listing_description,
						description: this.model_data.description,
						description_rich_text: this.model_data.description_rich_text,
						what_to_expect: this.model_data.what_to_expect,
						attendee_information: this.model_data.attendee_information,
						access_requirements: this.model_data.access_requirements,
						activity_image: this.model_data.activity_image,
						activity_lead: [ this.model_data.activity_lead ],
						booking_link_url: this.model_data.booking_link_url,
						booking_link_text: this.model_data.booking_link_text,
						start_date: {
							date: this.model_data.start_date && this.model_data.start_date.length > 0 ? this.model_data.start_date[0] : null,
							timezone: 'Europe/London',
						},
						end_date: {
							date: this.model_data.end_date && this.model_data.end_date.length > 0 ? this.model_data.end_date[0] : null,
							timezone: 'Europe/London',
						},
						start_time: {
							time: this.model_data.start_time,
							timezone: 'Europe/London',
						},
						end_time: {
							time: this.model_data.end_time,
							timezone: 'Europe/London',
						},
						bookings_available_from: {
							date: this.model_data.bookings_available_from_date && this.model_data.bookings_available_from_date.length > 0 ? this.model_data.bookings_available_from_date[0] : '',
							time: this.model_data.bookings_available_from_time,
							timezone: 'Europe/London',
						},
						capacity: this.model_data.capacity,
						waiting_list_capacity: this.model_data.waiting_list_capacity,
						location: this.model_data.location ? [ this.model_data.location ] : [],
						tool_instances: this.model_data.tool_instances,
					}
				}
			).then( ( response ) => {
				this.$messages.addNotice( 'Saved.' );
				if ( this.content_mode === CONTENT_MODE_EDIT ) {
					this.$router.push( {
						name: 'bookings__all_activities'
					} );
					return;
				}
				// Unlike GraphQL, the regular action API doesn't return the
				// saved entry back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_activity,
					{ id: response.id }
				).then( ( response ) => {
					this.model_data = this.convertActivityData( response.data.entry );
					this.$router.push( {
						name: 'bookings__edit_activity',
						params: {
							id: this.model_data.id,
						},
					} );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		refreshActivityData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.$craftGraphqlApiClient.query(
				gql_query_activity,
				{ id: this.$route.params.id }
			).then( ( response ) => {
				if (
					response.data.entry.typeHandle !== ACTIVITY_TYPE_PUBLIC_EVENT
					&& response.data.entry.typeHandle !== ACTIVITY_TYPE_LEGACY_EVENT_WEBSITE
				) {
					this.attendee_data = this.convertAttendeeData(
						response.data.entry.attendees
					);
					this.waiting_list_data = this.convertWaitingListData(
						response.data.entry.waiting_list
					);
				}
				this.model_data = this.convertActivityData( response.data.entry );
				this.attendees_selected_indexes = [];
				this.waiting_list_selected_indexes = [];
				this.show_train_attendees_modal = false;
				this.show_contact_attendees_modal = false;
				this.show_contact_waitlist_modal = false;
				this.show_warn_modal = false;
				this.show_remove_modal = false;
				this.show_cancel_modal = false;
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		addSearchedAttendeeToActivity() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.$craftActionApiClient.query(
				'iom/booking/save-activity-booking',
				{
					user_id: parseInt( this.attendees_searched_member_id, 10 ),
					activity_id: parseInt( this.model_data.id, 10 ),
					type: ACTIVITY_BOOKING_TYPE_REGULAR,
				}
			).then( () => {
				this.attendees_searched_member_id = null;
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				alert( Object.values( error.response.data.errors ).flat().join( '\n' ) );
			} ).finally( () => {
				this.refreshActivityData();
			} );
		},
		addSearchedAttendeeToWaitingList() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.$craftActionApiClient.query(
				'iom/booking/save-activity-booking',
				{
					user_id: parseInt( this.waiting_list_searched_member_id, 10 ),
					activity_id: parseInt( this.model_data.id, 10 ),
					type: ACTIVITY_BOOKING_TYPE_WAITLIST,
				}
			).then( () => {
				this.waiting_list_searched_member_id = null;
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				alert( Object.values( error.response.data.errors ).flat().join( '\n' ) );
			} ).finally( () => {
				this.refreshActivityData();
			} );
		},
		async markSelectedAttendeesAsAttended() {
			const selected_member_ids = this.getSelectedAttendeeData().map(
				item => item[0].member_id
			);
			const requests = [];
			selected_member_ids.forEach( member_id => {
				requests.push( this.$craftActionApiClient.query(
					'iom/booking/mark-activity-bookings-as-attended',
					{
						activity_id: this.model_data.id,
						user_id: member_id,
					}
				) );
			} );
			this.loading_state = LOADING_STATE_OVERLAY;
			await Promise.all( requests );
			this.refreshActivityData();
		},
		async markSelectedAttendeesAsNotAttended() {
			const selected_member_ids = this.getSelectedAttendeeData().map(
				item => item[0].member_id
			);
			const requests = [];
			selected_member_ids.forEach( member_id => {
				requests.push( this.$craftActionApiClient.query(
					'iom/booking/mark-activity-bookings-as-not-attended',
					{
						activity_id: this.model_data.id,
						user_id: member_id,
					}
				) );
			} );
			this.loading_state = LOADING_STATE_OVERLAY;
			await Promise.all( requests );
			this.refreshActivityData();
		},
		async markSelectedAttendeesAsInducted() {
			// Not sure if this conditional is necessary, maybe just to be safe?
			if ( this.model_data.activity_type !== ACTIVITY_TYPE_NEW_MEMBER_INDUCTION ) {
				return;
			}
			const selected_member_ids = this.getSelectedAttendeeData().map(
				item => item[0].member_id
			);
			const requests = [];
			selected_member_ids.forEach( member_id => {
				requests.push( this.$craftActionApiClient.query(
					'iom/member/induct-member',
					{
						user_id: member_id,
					}
				) );
			} );
			this.loading_state = LOADING_STATE_OVERLAY;
			await Promise.all( requests );
			this.refreshActivityData();
		},
		async markSelectedAttendeesAsTrained() {
			if ( this.activity_training_tool_classes.length === 0 ) {
				return;
			}
			const selected_member_ids = this.getSelectedAttendeeData().map(
				item => item[0].member_id
			);
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/member/grant-bulk-tool-class-access',
				{
					grantee_ids: selected_member_ids,
					tool_class_ids: this.activity_training_tool_classes,
				}
			);
			this.loading_state = LOADING_STATE_NONE;
			this.refreshActivityData();
		},
		async convertWaitlistBookingToRegular( row ) {
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/booking/convert-waitlist-bookings-to-regular',
				{
					activity_id: this.model_data.id,
					user_id: row[0].member_id,
				}
			);
			this.refreshActivityData();
		},
		async removeSelectedAttendeeBookings() {
			const selected_member_ids = this.getSelectedAttendeeData().map(
				item => item[0].member_id
			);
			const requests = [];
			selected_member_ids.forEach( member_id => {
				requests.push( this.$craftActionApiClient.query(
					'iom/booking/remove-attendee-booking-from-activity',
					{
						activity_id: this.model_data.id,
						user_id: member_id,
					}
				) );
			} );
			this.loading_state = LOADING_STATE_OVERLAY;
			await Promise.all( requests );
			this.refreshActivityData();
		},
		//async removeSelectedWaitlistBookings() {
		//	const selected_member_ids = this.getSelectedWaitlistData().map(
		//		item => item[0].member_id
		//	);
		//	const requests = [];
		//	selected_member_ids.forEach( member_id => {
		//		requests.push( this.$craftActionApiClient.query(
		//			'iom/booking/remove-waitlist-booking-from-activity',
		//			{
		//				activity_id: this.model_data.id,
		//				user_id: member_id,
		//			}
		//		) );
		//	} );
		//	this.loading_state = LOADING_STATE_OVERLAY;
		//	await Promise.all( requests );
		//	this.refreshActivityData();
		//},
		async removeAttendeeBooking( row ) {
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/booking/remove-attendee-booking-from-activity',
				{
					activity_id: this.model_data.id,
					user_id: row[0].member_id,
				}
			);
			this.refreshActivityData();
		},
		async removeWaitlistBooking( row ) {
			this.loading_state = LOADING_STATE_OVERLAY;
			await this.$craftActionApiClient.query(
				'iom/booking/remove-waitlist-booking-from-activity',
				{
					activity_id: this.model_data.id,
					user_id: row[0].member_id,
				}
			);
			this.refreshActivityData();
		},
		async issueWarningsToSelectedAttendees() {
			const selected_member_ids = this.getSelectedAttendeeData().map(
				item => item[0].member_id
			);
			const requests = [];
			selected_member_ids.forEach( member_id => {
				requests.push( this.$craftActionApiClient.query(
					'iom/member/issue-warning',
					{
						user_id: member_id,
						reason: this.mass_warning_reason,
					}
				) );
			} );
			this.loading_state = LOADING_STATE_OVERLAY;
			await Promise.all( requests );
			this.mass_warning_reason = '';
			this.refreshActivityData();
		},
		async contactSelectedAttendees() {
			const selected_member_ids = this.getSelectedAttendeeData().map(
				item => item[0].member_id
			);
			const requests = [];
			selected_member_ids.forEach( member_id => {
				requests.push( this.$craftActionApiClient.query(
					'iom/member/email-attendee',
					{
						activity_id: this.model_data.id,
						user_id: member_id,
						message: this.mass_contact_message,
					}
				) );
			} );
			this.loading_state = LOADING_STATE_OVERLAY;
			await Promise.all( requests );
			this.mass_contact_message = '';
			this.refreshActivityData();
		},
		async contactSelectedWaitlist() {
			const selected_member_ids = this.getSelectedWaitlistData().map(
				item => item[0].member_id
			);
			const requests = [];
			selected_member_ids.forEach( member_id => {
				requests.push( this.$craftActionApiClient.query(
					'iom/member/email-attendee',
					{
						activity_id: this.model_data.id,
						user_id: member_id,
						message: this.mass_contact_message,
					}
				) );
			} );
			this.loading_state = LOADING_STATE_OVERLAY;
			await Promise.all( requests );
			this.mass_contact_message = '';
			this.refreshActivityData();
		},
		async refreshToolInstances() {
			if ( !this.tool_instances_are_retrievable ) {
				return;
			}

			this.available_tool_instances = [];

			this.temporal_string = this.getTemporalString( this.model_data );

			const start_date = this.model_data.start_date && this.model_data.start_date.length > 0 ? this.model_data.start_date[0] : null;
			const end_date = this.model_data.activity_type !== ACTIVITY_TYPE_LEGACY_EVENT && this.model_data.end_date && this.model_data.end_date.length > 0 ? this.model_data.end_date[0] : start_date;
			const start_datetime = start_date ? start_date + ' ' + this.model_data.start_time : null;
			const end_datetime = end_date ? end_date + ' ' + this.model_data.end_time : null;

			if ( start_datetime >= end_datetime ) {
				return;
			}

			this.loading_tool_instances = true;

			const response = await this.$craftGraphqlApiClient.query(
				gql_query_bookable_tool_instances_by_date_range,
				{
					ignore_activity_id: ( this.model_data.id ? parseInt( this.model_data.id, 10 ) : null ),
					ignore_opening_hours: true,
					location_id: this.model_data.activity_type === ACTIVITY_TYPE_LEGACY_EVENT ? this.$bloomsburyEntryId : parseInt( this.model_data.location, 10 ),
					from: start_datetime,
					until: end_datetime,
				}
			);
			this.available_tool_instances = response.data.bookableToolInstancesByDateRange;

			this.model_data.tool_instances = ( this.model_data.tool_instances || [] ).filter(
				id => this.available_tool_instances.map( item => item.id ).includes( id )
			);
			this.loading_tool_instances = false;
		},
		async refreshToolClasses() {
			if (
				!this.model_data.tool_instances
				|| this.model_data.tool_instances.length === 0
			) {
				return [];
			}
			const response = await this.$craftGraphqlApiClient.query(
				gql_query_tools_by_id,
				{
					ids: this.model_data.tool_instances,
				}
			);
			this.activity_tool_class_options = convertCraftEntriesToSelectOptions(
				response.data.entries
					.filter( entry => entry.tool_class.length > 0 )
					.map( entry => entry.tool_class )
					.reduce( ( acc, tool_class ) => acc.concat( tool_class ), [] )
					.filter( ( tool_class, index, self ) => {
						// Filter out duplicates.
						return self.find( item => tool_class.id === item.id ) === tool_class;
					} )
				,
				entry => entry.title + ( entry.typeHandle === 'parent_class' ? ' (all)' : '' )
			);
		},
		cancelActivity() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_cancel_modal = false;
			this.$craftActionApiClient.query(
				'iom/booking/cancel-activity',
				{
					id: parseInt( this.model_data.id, 10 ),
					notify_attendees: this.cancel_activity_notify_attendees,
					details: this.cancel_activity_cancellation_details,
				}
			).then( () => {
				this.$router.push( {
					name: 'bookings__all_activities',
				} );
			} );
		},
		getSelectedAttendeeData() {
			return this.attendee_data.filter( ( item, index ) => {
				return this.attendees_selected_indexes.indexOf( index ) > -1;
			} );
		},
		getSelectedWaitlistData() {
			return this.waiting_list_data.filter( ( item, index ) => {
				return this.waiting_list_selected_indexes.indexOf( index ) > -1;
			} );
		},
		goToActivityDuplicate() {
			this.$router.push( {
				name: 'bookings__duplicate_activity',
				params: {
					id: this.model_data.id,
				},
			} );
			this.$refs.tabs.setActiveTab( 'activity_details' );
		},
		goToMember( row ) {
			this.$router.push( {
				name: 'members__edit_member',
				params: {
					id: row[0].member_id
				},
			} );
		},
		getTemporalString( data ) {
			return `${data.location} ${data.start_date.join( '|' )} ${( data.end_date ?? [] ).join( '|' )} ${data.start_time} ${data.end_time}`;
		},
		exportData() {
			window.location = `/manage/export/activity-bookings?activity_id=${this.model_data.id}`;
		},
	},
};

</script>
