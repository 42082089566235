
<template>
	<div class="paginated-table">
		<table class="paginated-table__table">
			<thead>
				<tr>
					<th v-if="selectable">
						<div
							class="paginated-table__checkbox"
						>
							<InputComponent
								:model-value="all_selected"
								:input-id="`${idPrefix}-selection-row-all`"
								type="checkbox"
								:hide-label="true"
								:no-margin="true"
								@update:model-value="toggleSelectAll"
							/>
						</div>
					</th>
					<th
						v-for="column, index in columns"
						:key="index"
					>
						{{ column.label }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					v-if="paginatedData.length === 0"
					class="paginated-table__empty-message"
				>
					<td :colspan="columns.length + ( selectable ? 1 : 0 )">
						No data to display.
					</td>
				</tr>
				<tr
					v-for="row in paginatedData"
					:key="row.index"
					class="paginated-table__content"
				>
					<td
						v-if="selectable"
						class="paginated-table__selectable"
					>
						<div
							class="paginated-table__checkbox"
						>
							<InputComponent
								:input-id="`${idPrefix}-selection-row-${ row.index }`"
								:model-value="selectedIndexes.indexOf( row.index ) > -1"
								type="checkbox"
								:hide-label="true"
								:no-margin="true"
								@update:model-value="is_checked => updateCheckedIndexes( row.index, is_checked )"
							/>
						</div>
					</td>
					<td
						v-for="value, index_inner in row.data"
						:key="index_inner"
						:class="{
							'paginated-table__action-td': columnIsActionButton( index_inner ),
							'paginated-table__action-td--floating': columnIsActionButtonFloating( index_inner )
						}"
						:data-label="columns[index_inner].label"
					>
						<div v-if="columnIsText( index_inner )">
							{{ value.visible }}
						</div>
						<div v-if="columnIsTextWithDetail( index_inner )">
							<template v-if="value.pending">
								<div class="paginated-table__loading-spinner" />
							</template>
							<template v-else>
								{{ value.visible.text }}
								<br v-if="value.visible.detail">
								<span
									v-if="value.visible.detail"
									class="paginated-table__detail-text"
								>
									{{ value.visible.detail }}
								</span>
							</template>
						</div>
						<div v-else-if="columnIsActionLink( index_inner )">
							<button
								class="paginated-table__action-link"
								@click="$emit(columns[index_inner].action_event, row.data)"
							>
								{{ value.visible }}
							</button>
						</div>
						<div v-if="columnIsActionLinkWithDetail( index_inner )">
							<button
								class="paginated-table__action-link"
								@click="$emit(columns[index_inner].action_event, row.data)"
							>
								{{ value.visible.text }}
								<br v-if="value.visible.detail">
								<span
									v-if="value.visible.detail"
									class="paginated-table__detail-text"
								>
									{{ value.visible.detail }}
								</span>
							</button>
						</div>
						<div
							v-else-if="columnIsActionButton( index_inner ) || columnIsActionButtonFloating( index_inner )"
							:class="{ 'flex flex--justify-end': index_inner === row.data.length - 1 }"
						>
							<button
								:class="getActionButtonClass( index_inner )"
								@click="$emit(columns[index_inner].action_event, row.data)"
							>
								{{ columns[index_inner].button_label }}
							</button>
						</div>
						<div v-else-if="columnIsStatus( index_inner )">
							<div
								class="paginated-table__status"
								:class="{
									'paginated-table__status--on': value.filterable.values.includes( columns[index_inner].statuses.on ),
									'paginated-table__status--off': value.filterable.values.includes( columns[index_inner].statuses.off ),
								}"
							>
								{{ value.visible }}
							</div>
						</div>
						<div v-else-if="columnIsBadge( index_inner )">
							<div
								class="paginated-table__badge"
								:class="{
									'paginated-table__badge--green': value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.green ),
									'paginated-table__badge--yellow': value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.yellow ),
									'paginated-table__badge--red': value.filterable.values.includes( columns[index_inner].values && columns[index_inner].values.red ),
								}"
							>
								{{ value.visible }}
							</div>
						</div>
						<div v-else-if="columnIsActionCheckbox( index_inner )">
							<div
								class="paginated-table__checkbox"
							>
								<InputComponent
									:input-id="`${idPrefix}-action-checkbox-row-${ row.index }-col-${ index_inner }`"
									:model-value="value.checked"
									type="checkbox"
									:hide-label="true"
									:no-margin="true"
									@update:model-value="value => $emit( columns[index_inner].action_event, { row: row.data, value } )"
								/>
							</div>
						</div>
						<div v-else-if="columnIsActionCheckboxAndTextWithDetail( index_inner )">
							<div
								class="paginated-table__checkbox-and-text-with-detail"
							>
								<div>
									<InputComponent
										:input-id="`${idPrefix}-action-checkbox-row-${ row.index }-col-${ index_inner }`"
										:model-value="value.checked"
										type="checkbox"
										:hide-label="true"
										:no-margin="true"
										@update:model-value="value => $emit( columns[index_inner].action_event, { row: row.data, value } )"
									/>
								</div>
								<div v-if="value.visible">
									{{ value.visible.text }}
									<br v-if="value.visible.detail">
									<span
										v-if="value.visible.detail"
										class="paginated-table__detail-text"
									>
										{{ value.visible.detail }}
									</span>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<PaginationComponent
		v-if="pageCount > 1"
		:page-count="pageCount"
		:current-page="current_page"
		@page-decrement="decrementPage"
		@page-increment="incrementPage"
		@page-change="changePage"
	/>
</template>

<script>

import PaginationComponent from '../components/PaginationComponent.vue';
import InputComponent from '../components/InputComponent.vue';

import {
	PAGINATED_TABLE_COLUMN_TEXT,
	PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_LINK,
	PAGINATED_TABLE_COLUMN_ACTION_LINK_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON,
	PAGINATED_TABLE_COLUMN_ACTION_BUTTON_FLOATING,
	PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX,
	PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX_AND_TEXT_WITH_DETAIL,
	PAGINATED_TABLE_COLUMN_STATUS,
	PAGINATED_TABLE_COLUMN_BADGE,
	TASK_STATUS_DONE,
	INCIDENT_STATUS_RESOLVED,
} from '../../../constants.js';

export default {
	components: {
		PaginationComponent,
		InputComponent
	},
	props: {
		idPrefix: { type: String, default: 'paginated-table' },
		columns: { type: Array, default: () => [] },
		data: { type: Array, default: () => [] },
		pageSize: { type: Number, default: 15 },
		activeFilters: { type: Array, default: () => [] },
		selectable: { type: Boolean, default: false },
		selectedIndexes: { type: Array, default: () => [] },
	},
	emits: [ 'update:selectedIndexes' ],
	data() {
		return {
			current_page: 0,
		};
	},
	computed: {
		all_selected() {
			return this.data.length > 0
				&& this.selectedIndexes.length === this.data.length;
		},
		pageCount() {
			return Math.ceil( this.transformedData.length / this.pageSize );
		},
		transformedData() {
			// We must preserve the original indexes so we can send the correct
			// selectedIndexes back even when filters/pagination are active.
			let data = this.data.map( ( row, index ) => {
				return {
					index: index,
					data: row,
				};
			} );

			this.changePage( 0 );

			this.activeFilters.forEach( filter => {
				if ( filter.name !== 'show_completed_tasks' && filter.name !== 'show_resolved_reports' && !filter.value ) {
					return;
				}
				if ( filter.name === 'member_query' || filter.name === 'tool_query' || filter.name === 'class_query' ) {
					data = data.filter( row => row.data.map( col => col && col.visible ? col.visible : [] ).join( ' ' ).toUpperCase().includes( filter.value.toUpperCase() ) );
					return;
				}
				if ( filter.name === 'show_completed_tasks' || filter.name === 'show_resolved_reports' ) {
					if ( filter.value ) {
						return;
					}
					if ( filter.name === 'show_completed_tasks' ) {
						data = data.filter( row => {
							return row.data.find( col => col && col.filterable && col.filterable.slug === filter.name ? !col.filterable.values.includes( TASK_STATUS_DONE ) : false );
						} );
					}
					if ( filter.name === 'show_resolved_reports' ) {
						data = data.filter( row => {
							return row.data.find( col => col && col.filterable && col.filterable.slug === filter.name ? !col.filterable.values.includes( INCIDENT_STATUS_RESOLVED ) : false );
						} );
					}
					return;
				}
				data = data.filter( row => {
					return row.data.find( col => {
						return col && col.filterable && col.filterable.slug === filter.name ? col.filterable.values.includes( filter.value ) : false;
					} );
				} );
			} );

			return data;
		},
		paginatedData() {
			const page_start = this.current_page * this.pageSize;
			const page_end = page_start + this.pageSize;

			return this.transformedData.slice( page_start, page_end );
		},
	},
	methods: {
		columnIsText( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_TEXT;
		},
		columnIsTextWithDetail( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_TEXT_WITH_DETAIL;
		},
		columnIsActionLink( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_LINK;
		},
		columnIsActionLinkWithDetail( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_LINK_WITH_DETAIL;
		},
		columnIsActionButton( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_BUTTON;
		},
		columnIsActionButtonFloating( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_BUTTON_FLOATING;
		},
		columnIsActionCheckbox( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX;
		},
		columnIsActionCheckboxAndTextWithDetail( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_ACTION_CHECKBOX_AND_TEXT_WITH_DETAIL;
		},
		columnIsStatus( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_STATUS;
		},
		columnIsBadge( index ) {
			return this.columns[index].type === PAGINATED_TABLE_COLUMN_BADGE;
		},
		changePage( index ) {
			this.current_page = index;
		},
		decrementPage() {
			if ( this.current_page > 0 ) {
				this.current_page--;
			}
		},
		incrementPage() {
			if ( this.current_page < ( this.pageCount - 1 ) ) {
				this.current_page++;
			}
		},
		updateCheckedIndexes( index, is_checked ) {
			const checked_indexes = [...this.selectedIndexes];
			const index_of_checked_index = checked_indexes.indexOf( index );
			if ( is_checked ) {
				if ( index_of_checked_index === -1 ) {
					checked_indexes.push( index );
				}
			} else {
				if ( index_of_checked_index > -1 ) {
					checked_indexes.splice( index_of_checked_index, 1 );
				}
			}
			this.$emit( 'update:selectedIndexes', checked_indexes );
		},
		toggleSelectAll( is_checked ) {
			if ( is_checked ) {
				this.$emit(
					'update:selectedIndexes',
					Array.from( Array( this.data.length ).keys() )
				);
				return;
			}
			this.$emit( 'update:selectedIndexes', [] );
		},
		getActionButtonClass( index ) {
			const classes = ['paginated-table__action-button'];
			if ( this.columns[index].button_variant ) {
				classes.push( `paginated-table__action-button--${this.columns[index].button_variant}` );
			}
			return classes.join( ' ' );
		}
	},
};

</script>
