
import { breakpoints } from '../variables.yml';

import 'focus-visible';
import zxcvbn from 'zxcvbn';
import intlTelInput from 'intl-tel-input';
import { Modal } from './vendor/modal.js';
import { getWindowWidth, setupShareLinks, buildThresholdList } from './helpers.js';

export * from './conditional-fields.js';
export * from './toggler.js';
export * from './carousel.js';
export * from './CookieConsentManager.js';
export * from './vue/manage/app.js';
export * from './vue/activity-booking-widget/app.js';
export * from './vue/tool-booking-widget/app.js';
export * from './vue/filter-widget/app.js';

export * from './algolia/search.js';

import './splash.js';

// Libs
import Tobii from '@midzer/tobii';

( () => {
	// Password strength
	const password = document.getElementById( 'password' );
	const meter = document.querySelector( '.form__password-strength' );
	if ( !password || !meter ) {
		return;
	}
	const meter_elem = meter.querySelector( '.form__password-meter' );
	if ( !meter_elem ) {
		return;
	}
	const strength = {
		0: 'Very weak',
		1: 'Weak',
		2: 'Okay',
		3: 'Good',
		4: 'Strong'
	};
	window.addEventListener( 'input', ( e ) => {
		if ( e.target === password ) {
			const val = password.value;
			const result = zxcvbn( val );
			meter_elem.setAttribute( 'data-value', result.score );
			const score_text = ( val !== '' ? strength[result.score] : 'Very weak' );
			meter.setAttribute( 'data-strength', score_text );
		}
	} );
} )();

( () => {
	// Mobile phone input
	const inputs = document.querySelectorAll( '[data-intl-tel-input]' );
	inputs.forEach( input => {
		intlTelInput( input, {
			preferredCountries: ['gb'],
			initialCountry: 'gb',
		} );
	} );
} )();

( () => {
	// Confirm buttons
	window.addEventListener( 'click', e => {
		if ( !( 'confirm' in e.target.dataset ) ) {
			return;
		}
		if ( !confirm( e.target.dataset.confirm ) ) {
			e.preventDefault();
		}
	} );
} )();

( () => {
	// SMS input
	const sms_input = document.querySelector( '.sms-input input[name=code]' );
	if ( !sms_input ) {
		return;
	}
	sms_input.addEventListener( 'input', e => {
		//e.target.value = e.target.value.replace( /[^0-9]/, '' );
		if ( e.target.value.length === 6 ) {
			e.target.closest( 'form' ).submit();
		}
	} );
} )();

( () => {
	// Accordion
	const accordion_items = document.querySelectorAll( '.accordion-item' );
	if ( !accordion_items ) {
		return;
	}

	for ( let i = 0; i < accordion_items.length; i += 1 ) {
		const accordion_button = accordion_items[i].querySelector( '.accordion-item__button' );
		const a11y_text = accordion_button.querySelector( '.accordion-item__show' );

		accordion_button.addEventListener( 'click', function() {
			if ( this.classList.contains( 'active' ) ) {
				closeAllPanels();
				return;
			}
			closeAllPanels();
			this.classList.add( 'active' );
			this.setAttribute( 'aria-expanded', true );
			a11y_text.innerText = 'Hide content';
			const panel = this.nextElementSibling;
			panel.style.maxHeight = panel.scrollHeight + 'px';
			panel.style.visibility = 'visible';
		} );
	}

	function closeAllPanels() {
		for ( let i = 0; i < accordion_items.length; i += 1 ) {
			const button = accordion_items[i].querySelector( '.accordion-item__button' );
			const a11y_text = button.querySelector( '.accordion-item__show' );
			const panel = accordion_items[i].querySelector( '.accordion-item__content' );
			panel.style.maxHeight = null;
			panel.style.visibility = 'hidden';
			button.classList.remove( 'active' );
			button.setAttribute( 'aria-expanded', false );
			a11y_text.innerText = 'Show content';
		}
	}
} )();

( () => {
	// Scroll to top

	const scroll_top_toggles = [
		'js-scroll-to-top',
		'meta__button--scroll-to-top',
	];

	window.addEventListener( 'click', ( e ) => {
		if ( scroll_top_toggles.some( class_name => e.target.classList.contains( class_name ) ) ) {
			scrollToTop();
		}
	} );

	function scrollToTop() {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	}
} )();

( () => {
	// Read more
	const read_more_containers = document.querySelectorAll( '.read-more__container' );
	const read_more_buttons = document.querySelectorAll( '.read-more__button' );
	if ( !read_more_containers || !read_more_buttons ) {
		return;
	}

	window.addEventListener( 'load', () => {
		for ( let i = 0; i < read_more_containers.length; i++ ) {
			handleShowReadMoreButton( read_more_containers[i] );
		}
	} );

	window.addEventListener( 'resize', () => {
		for ( let i = 0; i < read_more_containers.length; i++ ) {
			handleShowReadMoreButton( read_more_containers[i] );
		}
	} );

	for ( let i = 0; i < read_more_buttons.length; i++ ) {
		read_more_buttons[i].addEventListener( 'click', () => {
			toggleExpandReadMore( read_more_containers[i], read_more_buttons[i] );
		} );
	}

	function toggleExpandReadMore( read_more_container, read_more_button ) {
		if ( read_more_container.classList.contains( 'expanded' ) ) {
			read_more_container.classList.remove( 'expanded' );
			read_more_button.setAttribute( 'aria-expanded', false );
			read_more_container.style.maxHeight = getMaxVisibleHeight( read_more_container ) + 'px';
			return;
		}
		read_more_container.classList.add( 'expanded' );
		read_more_button.setAttribute( 'aria-expanded', true );
		read_more_container.style.maxHeight = '2000px';
	}

	function handleShowReadMoreButton( read_more_container ) {
		if ( getWindowWidth() > breakpoints.f.width ) {
			if ( read_more_container.offsetHeight > getMaxVisibleHeight( read_more_container ) && getMaxVisibleHeight( read_more_container ) > 0 ) {
				read_more_container.style.maxHeight = getMaxVisibleHeight( read_more_container ) + 'px';
				if ( !read_more_container.classList.contains( 'enabled' ) ) {
					read_more_container.classList.add( 'enabled' );
				}
			}
		}
	}

	function getMaxVisibleHeight( read_more_container ) {
		const max_height = read_more_container.dataset.visibleHeight;
		return max_height;
	}

} )();

( () => {
	// Video embeds
	function buildVideoPlayer( container ) {
		const embed_url = container.getAttribute( 'data-embed-url' );
		const iframe = document.createElement( 'iframe' );
		iframe.setAttribute( 'width', 1640 );
		iframe.setAttribute( 'height', 923 );
		iframe.setAttribute( 'src', embed_url );
		iframe.setAttribute( 'title', 'Embedded video content' );
		iframe.setAttribute( 'frameborder', 0 );
		iframe.setAttribute( 'allowfullscreen', true );
		iframe.setAttribute( 'allow', 'autoplay' );
		container.classList.add( 'flex-container' );
		container.classList.add( 'video-embed--playing' );
		container.innerHTML = '';
		container.appendChild( iframe );
	}

	const videos = document.querySelectorAll( '.video-embed' );
	for ( let j = 0; j < videos.length; j += 1 ) {
		videos[j].addEventListener( 'click', ( e ) => {
			e.preventDefault();
			e.stopPropagation();
			buildVideoPlayer( videos[j] );
		} );
	}
} )();

( () => {
	// Toggle hide filters
	const button = document.querySelector( '.js-toggle-filters ' );
	if ( !button ) {
		return;
	}

	const filter_parent = document.querySelector( '.filter__parent' );
	const filter_container = document.querySelector( '.filter' );
	const hit_container = document.querySelector( '.filter__filtered' );
	let hidden = false;

	button.addEventListener( 'click', () => {
		hidden = !hidden;
		handleButton();
		toggleFilterDisplay();
	} );

	function handleButton() {
		if ( hidden ) {
			button.innerText = 'Show filters';
			button.classList.toggle( 'hidden', true );
		} else {
			button.innerText = 'Hide filters';
			button.classList.toggle( 'hidden', false );
		}
	}

	function toggleFilterDisplay() {
		filter_container.classList.toggle( 'hidden' );
		filter_parent.classList.toggle( 'hidden' );
		if ( hidden ) {
			hit_container.classList.remove( 'a9-12', 'c9-12' );
			hit_container.classList.add( 'a12-12' );
			hit_container.classList.add( 'filter__filtered--filter-hidden' );
			return;
		}
		hit_container.classList.remove( 'a12-12' );
		hit_container.classList.add( 'a9-12', 'c9-12' );
		hit_container.classList.remove( 'filter__filtered--filter-hidden' );
	}

	const view_buttons = document.querySelectorAll( '.filter__view' );

	view_buttons.forEach( button => {
		button.addEventListener( 'click', ( e ) => {
			for ( let i = 0; i < view_buttons.length; i++ ) {
				const btn = view_buttons[i];
				btn.classList.remove( 'filter__view--selected' );
			}
			button.classList.add( 'filter__view--selected' );
			toggleViewDisplay( e.target.dataset.view );
		} );
	} );

	function toggleViewDisplay( view ) {
		const requested_view_class = `filter__filtered--${view}`;
		const views = [
			'filter__filtered--full',
			'filter__filtered--small',
			'filter__filtered--list',
		];

		views.forEach( view => {
			// remove other view classes
			if ( hit_container.classList.contains( view ) ) {
				hit_container.classList.remove( view );
			}
		} );

		// add requested view class
		hit_container.classList.add( requested_view_class );

	}
} )();

( () => {
	// Toggle filters mobile
	const button = document.querySelector( '.filter__toggle-mobile' );
	if ( !button ) {
		return;
	}

	const container = document.querySelector( '.filter' );
	const filter_toggles = [
		'filter__close',
		'filter__toggle-mobile',
		'meta__button--toggle-filter',
	];

	window.addEventListener( 'click', ( e ) => {
		if ( filter_toggles.some( class_name => e.target.classList.contains( class_name ) ) ) {
			toggleFilter();
		}
	} );

	function toggleFilter() {
		const filter_open = container.classList.contains( 'filter--open' );
		container.classList.toggle( 'filter--open', !filter_open );
		document.body.classList.toggle( 'filter-open', !filter_open );
	}
} )();

( () => {
	// Gallery

	const gallery_links = document.querySelectorAll( '.gallery__link' );
	if ( gallery_links.length < 1 ) {
		return;
	}
	new Tobii( {
		selector: '.gallery__link',
		zoom: false,
		threshold: 50,
		counter: true,
		captions: true,
		captionsSelector: 'self',
		captionAttribute: 'data-caption'
	} );
} )();

( () => {
	// Toggle show password
	const checkbox = document.getElementById( 'toggle-show-password' );
	if ( !checkbox ) {
		return;
	}
	const password = document.getElementById( 'password' );

	checkbox.addEventListener( 'change', () => {
		if ( password.type === 'password' ) {
			password.type = 'text';
		} else {
			password.type = 'password';
		}
	} );

} )();

( () => {
	// header select effect
	const selectors = document.querySelectorAll( '.js-selector' );
	if ( !selectors ) {
		return;
	}

	selectors.forEach( selector => {
		const nav_items = document.querySelectorAll( selector.dataset.itemsClass );
		const ref = selector.parentElement.getBoundingClientRect();

		const selected = () => {
			return selector.parentElement.querySelector( '.selected > a' ).getBoundingClientRect();
		};

		setTimeout( () => {
			resetSelectorPosition();
		}, 200 );

		nav_items.forEach( item => {
			item.addEventListener( 'mouseover', handleMouseOver );
			item.addEventListener( 'mouseout', handleMouseOut );
			item.addEventListener( 'keyup', ( e ) => {
				if ( e.key === 'Tab' ) {
					handleMouseOver( e );
				}
			} );
		} );

		window.addEventListener( 'resize', () => {
			handleWindowResize();
		} );

		function handleMouseOver( e ) {
			setSelectorPosition( e.target );
		}

		function handleMouseOut() {
			resetSelectorPosition();
		}

		function setSelectorPosition( el ) {
			const rect = el.getBoundingClientRect();
			const pos = Math.round( rect.x - ref.x );

			if ( el.tagName === 'A' ) {
				selector.style.width = `${Math.round( rect.width )}px`;
			} else {
				selector.style.width = `${Math.round( el.querySelector( 'a' ).offsetWidth )}px`;
			}
			selector.style.transform = `translateX( ${pos}px )`;
		}

		function resetSelectorPosition() {
			selector.style.width = `${selected().width}px`;
			selector.style.transform = `translateX( ${Math.round( selected().x - ref.x ) + 0.1 }px )`;
		}

		function handleWindowResize() {
			if ( getWindowWidth() < breakpoints.d.width ) {
				return;
			}
			selector.style.width = `${selected().width}px`;
			selector.style.transform = `translateX( ${Math.round( selected().x - ref.x ) + 0.1 }px )`;
		}
	} );

} )();

( () => {
	// Highlight anchor link at its position-
	const anchor_link_container = document.querySelector( '.anchor-links' );
	if ( !anchor_link_container ) {
		return;
	}

	const anchor_links = document.querySelectorAll( '.anchor-links__list-item' );
	const options = {
		rootMargin: `-62px 0px -${window.innerHeight - 162}px 0px`,
		threshold: buildThresholdList( 10 ),
	};
	const observer = new IntersectionObserver( ( entries ) => {
		entries.forEach( entry => {
			const related_anchor = document.querySelector( `a[href="#${entry.target.id}"]` ).parentNode;
			if ( entry.intersectionRatio > 0 ) {
				anchor_links.forEach( anchor => {
					anchor.classList.remove( 'anchor-links__list-item--active' );
				} );
				related_anchor.classList.add( 'anchor-links__list-item--active' );
			}
		} );
	}, options );

	anchor_links.forEach( anchor => {
		const href = anchor.firstElementChild.getAttribute( 'href' );
		const section = document.getElementById( href.replace( new RegExp( '#', 'g' ), '' ) );
		observer.observe( section );
	} );

	window.addEventListener( 'click', ( e ) => {
		if ( e.target.parentElement.classList.contains( 'anchor-links__list-item' ) ) {
			e.preventDefault();
			const target_element = document.querySelector( e.target.getAttribute( 'href' ) );
			const subnav = document.querySelector( '.subnav' );
			if ( subnav ) {
				const subnav_height = subnav.getBoundingClientRect().height;
				window.scrollTo( {
					top: target_element.offsetTop - ( subnav_height + 30 ),
					behavior: 'smooth',
				} );
				return;
			}
			window.scrollTo( {
				top: target_element.offsetTop - 30,
				behavior: 'smooth',
			} );
		}
	} );

} )();

( () => {
	// Fix nav on scroll
	const subnav = document.querySelector( '.subnav' );
	if ( !subnav ) {
		return;
	}
	const main_nav = document.querySelector( '.header' );
	const header_height = main_nav.getBoundingClientRect().height;

	window.addEventListener( 'scroll', () => {
		if ( window.scrollY > header_height ) {
			subnav.classList.add( 'fixed' );
			document.body.classList.add( 'fixed' );
		}
		if ( window.scrollY < header_height ) {
			subnav.classList.remove( 'fixed' );
			document.body.classList.remove( 'fixed' );
		}
	} );
} )();

( () => {
	// Copy current URL to clipboard
	const btn = document.querySelector( '.js-copy-url' );
	if ( !btn ) {
		return;
	}

	btn.addEventListener( 'click', ( e ) => {
		e.preventDefault();
		navigator.clipboard.writeText( window.location.href );
		e.target.insertAdjacentHTML( 'afterend', '<div class="social-share__confirm">Link copied!</div>' );
		setTimeout( () => {
			document.querySelectorAll( '.social-share__confirm' ).forEach( el => el.remove() );
		}, 3000 );
	} );
} )();

( () => {
	// Switch tables
	const dropdown = document.querySelector( '.dropdown' );
	if ( !dropdown ) {
		return;
	}

	const list_items = dropdown.querySelectorAll( '.dropdown__item' );
	const list = dropdown.querySelector( '.dropdown__list' );
	const tables = document.querySelectorAll( '.js-table' );
	const toggle = dropdown.querySelector( '.dropdown__toggle' );

	toggle.addEventListener( 'click', () => {
		list.style.display = 'block';
	} );

	list_items.forEach( item => {
		const table = document.getElementById( item.dataset.tableId );
		item.addEventListener( 'click', () => {
			tables.forEach( ( table ) => table.classList.add( 'hidden' ) );
			list_items.forEach( ( item ) => item.classList.remove( 'dropdown__item--selected' ) );
			item.classList.add( 'dropdown__item--selected' );
			table.classList.remove( 'hidden' );
			toggle.innerText = item.innerText;
			list.style.display = 'none';
		} );
	} );
} )();

( () => {
	// Mobile submenu
	const subnav_items = document.querySelectorAll( '.has-subnav' );
	if ( !subnav_items ) {
		return;
	}

	window.addEventListener( 'click', ( e ) => {
		if ( !e.target.classList.contains( 'has-subnav' ) ) {
			return;
		}
		if ( getWindowWidth() < breakpoints.d.width ) {
			e.preventDefault();
			handleNav( e.target );
		}
	} );

	function handleNav( toggler ) {
		const togglee = toggler.nextElementSibling;
		subnav_items.forEach( item => {
			if ( item !== toggler ) {
				item.classList.toggle( 'open', false );
				item.nextElementSibling.classList.toggle( 'open', false );
			}
		} );
		toggler.classList.toggle( 'open' );
		togglee.classList.toggle( 'open' );
	}

} )();

( () => {
	// Dismiss flash
	const flashes = document.querySelectorAll( '.js-dismiss' );
	if ( !flashes ) {
		return;
	}

	flashes.forEach( flash => {
		flash.addEventListener( 'click', ( e ) => {
			e.target.parentElement.remove();
		} );
	} );
} )();

( () => {
	// Bookmark
	let modal = null;
	const bookmark_els = document.querySelectorAll( '[data-bookmark]' );
	if ( !bookmark_els ) {
		return;
	}
	let existing_bookmarks = JSON.parse(
		localStorage.getItem(
			'materials_library_bookmarks'
		)
	);
	if ( !Array.isArray( existing_bookmarks ) ) {
		existing_bookmarks = [];
	}
	if ( bookmark_els ) {
		bookmark_els.forEach( item => {
			const index = existing_bookmarks.indexOf( item.dataset.bookmarkMaterialId );
			toggleBookmarkedClass( item, index );
		} );
	}
	window.addEventListener( 'click', ( e ) => {
		if ( !e.target.hasAttribute( 'data-bookmark-material-id' ) ) {
			return;
		}
		e.preventDefault();
		const material_id = e.target.getAttribute(
			'data-bookmark-material-id'
		);
		const index = existing_bookmarks.indexOf( material_id );
		if ( index < 0 ) {
			existing_bookmarks.push( material_id );
			e.target.classList.add( 'material-listing__bookmark--saved' );
			e.target.classList.add( 'bookmark-saved' );
			showModal( material_id, false );
		} else {
			existing_bookmarks.splice( index, 1 );
			e.target.classList.remove( 'material-listing__bookmark--saved' );
			e.target.classList.remove( 'bookmark-saved' );
			showModal( material_id, true );
		}
		localStorage.setItem(
			'materials_library_bookmarks',
			JSON.stringify( existing_bookmarks )
		);
	} );

	async function showModal( id, remove = false ) {
		const url = '/materials-library/library/save-bookmark?' + new URLSearchParams( {
			'material_id': id,
		} );
		try {
			const response = await fetch( url, {
				method: 'GET',
			} );

			const result = await response.json();
			if ( null === modal ) {
				modal = new Modal();
			}
			modal.updateModalContent( result, remove );

		} catch ( error ) {
			// eslint-disable-next-line no-console
			console.log( 'Error: ', error );
		}
	}

	function toggleBookmarkedClass( el, index ) {
		if ( index < 0 ) {
			el.classList.remove( 'bookmark-saved' );
		} else {
			el.classList.add( 'bookmark-saved' );
		}
	}
} )();

setupShareLinks();
