
<template>
	<div class="booking">
		<h2
			v-if="!viewIsSuccess()"
			class="booking__title"
		>
			Make a booking
		</h2>
		<template v-if="viewIsLoading()">
			Loading...
		</template>
		<template v-else-if="viewIsSuccess()">
			<div class="booking__confirmation">
				<p>Your booking has been confirmed, you’ll receive an email shortly with additional details.</p>
			</div>
			<div class="booking__message">
				<p>Please note: Latecomers will not be admitted. If you cannot make your booking please cancel at least 24 hours in advance. Members who repeatedly do not attend their bookings will have their membership suspended.</p>
			</div>
			<div>
				<a
					href="/workshop/tools"
					class="button button--large button--fullwidth button--invert button--center"
				>
					Back to all tools
				</a>
			</div>
		</template>
		<template v-else-if="viewIsConfirmBooking()">
			<div class="booking__detail">
				<dl class="booking__detail-row">
					<dt>Tool:</dt>
					<dd>{{ toolClassTitle }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Your name:</dt>
					<dd>{{ craft_user_data.fullName }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Location:</dt>
					<dd>{{ getLocationNameFromId( booking_location ) }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Date:</dt>
					<dd>{{ booking_date }}</dd>
				</dl>
				<dl class="booking__detail-row">
					<dt>Time:</dt>
					<dd>{{ getTimeslotRangeFromDate( booking_datetime ) }}</dd>
				</dl>
			</div>
			<div class="form__set booking__agreement">
				<div class="form__checkbox">
					<input
						id="booking-widget-member-agreement"
						v-model="agrees_to_agreement"
						type="checkbox"
					>
					<label for="booking-widget-member-agreement">
						I agree to the
						<a href="/members/member-agreement">
							Member Agreement
						</a>
						of Institute of Making
					</label>
				</div>
			</div>
			<button
				class="button button--large button--fullwidth button--invert button--center"
				:disabled="!booking_can_be_made"
				@click="bookTool"
			>
				Confirm booking
			</button>
		</template>
		<template v-else-if="viewIsError()">
			<div class="booking__message">
				<h3 class="booking__heading booking__heading--warning">
					Booking failed
				</h3>
				<p
					v-for="error, index in error_messages"
					:key="index"
				>
					{{ error }}
				</p>
			</div>
			<button
				class="button button--fullwidth button--invert button--center"
				@click="resetErrorState"
			>
				Go back
			</button>
		</template>
		<template v-else>
			<div class="form__set">
				<label
					for="booking-widget-location"
					class="form__label"
				>
					Select a location
				</label>
				<div class="form__icon form__icon--down">
					<select
						id="booking-widget-location"
						v-model="booking_location"
						class="form__select"
					>
						<option
							v-for="location in locations"
							:key="location.id"
							:value="location.id"
						>
							{{ location.title }}
						</option>
					</select>
				</div>
			</div>
			<div class="form__set form__set--less-margin">
				<label
					for="booking-widget-date"
					class="form__label"
				>
					Choose a date and time
					<span class="form__descriptor">
						Please note you can only book tools up to two months in advance.
					</span>
				</label>
				<DatePickerComponent
					input-id="booking-widget-date"
					:dates="booking_date"
					mode="single"
					:loading="loading_dates"
					:inline="false"
					:min-date="min_date_formatted"
					:max-date="max_date_formatted"
					:use-enabled-dates-mode="true"
					:enabled-dates="calendar_enabled_dates"
					@update="updateBookingDate"
					@update-month="updateCalendarEnabledDates"
				/>
			</div>
			<div class="form__set">
				<label
					for="booking-widget-timeslot"
					class="invisible"
				>
					Select a time
				</label>
				<div class="form__icon form__icon--down">
					<select
						id="booking-widget-timeslot"
						v-model="booking_datetime"
						class="form__select"
						:disabled="!timeslots_are_retrievable"
					>
						<option
							v-for="timeslot, index in timeslots"
							:key="index"
							:value="timeslot"
						>
							{{ getTimeslotRangeFromDate( timeslot ) }}
						</option>
					</select>
				</div>
			</div>
			<button
				class="button button--large button--fullwidth button--invert button--center"
				:disabled="!booking_can_be_confirmed"
				@click="setViewToConfirmBooking"
			>
				Book now
			</button>
		</template>
	</div>
</template>

<script>

import { storeToRefs } from 'pinia';

import { useUserStore } from '../shared/stores/user';

import moment from 'moment';

import DatePickerComponent from '../shared/components/DatePickerComponent.vue';

import gql_query_dates_with_bookable_tool_instances from '../shared/graphql/query/DatesWithBookableToolInstances.gql';

const VIEW_INITIAL = 'initial';
const VIEW_CONFIRM_BOOKING = 'confirm_booking';
const VIEW_LOADING = 'loading';
const VIEW_SUCCESS = 'success';
const VIEW_ERROR = 'error';

export default {
	components: {
		DatePickerComponent,
	},
	props: {
		toolClassId: {
			required: true,
			type: String,
		},
		toolClassTitle: {
			required: true,
			type: String,
		},
		toolClassTimeslotLength: {
			required: true,
			type: Number,
		},
		locations: {
			required: true,
			type: Array,
		},
	},
	setup() {
		const user_store = useUserStore();
		user_store.loadSession();
		const { craft_user_data } = storeToRefs( user_store );
		return { craft_user_data };
	},
	data() {
		// Can't book earlier than today.
		const min_datepicker_date = moment();
		// Can't book later than two months into future.
		const max_datepicker_date = moment().add( 2, 'months' );

		return {
			booking_location: null,
			booking_date: null, // Purely for UI purposes.
			booking_datetime: null, // This one gets sent to booking system.

			current_view: VIEW_INITIAL,

			error_messages: [],

			calendar_enabled_dates: [],
			timeslots: [],

			min_date_formatted: min_datepicker_date.format( 'YYYY-MM-DD' ),
			max_date_formatted: max_datepicker_date.format( 'YYYY-MM-DD' ),

			agrees_to_agreement: false,

			loading_dates: false,

			// Hold copy of what datepicker is looking at, to control the
			// calendar enabled dates. I don't like copying the datepicker state
			// but it's a pain to get the datepicker to 're-month' itself based
			// on changes in this component otherwise.
			// Ideally the year/month view status of DatepickerComponent would
			// be props but it is not currently.
			booking_year: '',
			booking_month: '',
		};
	},
	computed: {
		booking_can_be_confirmed() {
			return this.booking_location !== null
				&& this.booking_location !== ''
				&& this.booking_datetime !== null
				&& this.booking_datetime !== '';
		},
		booking_can_be_made() {
			return this.agrees_to_agreement;
		},
		timeslots_are_retrievable() {
			return (
				this.booking_location !== null
				&& this.booking_location !== ''
				&& this.booking_date !== null
				&& this.booking_date !== ''
			);
		},
	},
	watch: {
		booking_location() {
			this.refreshDaysWithBookableToolInstances();
			this.refreshTimeslots();
		},
		booking_date() {
			this.refreshTimeslots();
		},
	},
	methods: {
		async bookTool() {
			if ( !this.booking_can_be_made ) {
				return;
			}
			this.current_view = VIEW_LOADING;
			this.$craftActionApiClient.query(
				'iom/booking/save-member-tool-booking',
				{
					tool_class_id: this.toolClassId,
					location_id: this.booking_location,
					timeslot_begins: this.booking_datetime,
				}
			).then( () => {
				this.current_view = VIEW_SUCCESS;
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.current_view = VIEW_ERROR;
				if ( !error.response.data?.errors ) {
					// Shouldn't happen.
					return;
				}
				this.error_messages = Object.values( error.response.data.errors ).reduce(
					( item, acc ) => {
						item.forEach( message => {
							acc.push( message );
						} );
						return acc;
					},
					[]
				);
			} );
		},
		async refreshDaysWithBookableToolInstances() {
			this.calendar_enabled_dates = [];
			this.loading_dates = true;
			if ( !this.booking_location ) {
				return;
			}
			const response = await this.$craftGraphqlApiClient.query(
				gql_query_dates_with_bookable_tool_instances,
				{
					tool_class_id: parseInt( this.toolClassId, 10 ),
					location_id: parseInt( this.booking_location, 10 ),
					year: this.booking_year,
					month: this.booking_month,
				}
			);
			this.calendar_enabled_dates = response.data.datesWithBookableToolInstances;
			this.loading_dates = false;
		},
		async refreshTimeslots() {
			this.timeslots = [];
			if ( !this.timeslots_are_retrievable ) {
				return;
			}
			this.timeslots = await this.$craftActionApiClient.query(
				'iom/booking/get-tool-class-timeslots',
				{
					tool_class_id: this.toolClassId,
					location_id: this.booking_location,
					date: this.booking_date,
				}
			);
		},
		viewIsConfirmBooking() {
			return this.current_view === VIEW_CONFIRM_BOOKING;
		},
		viewIsSuccess() {
			return this.current_view === VIEW_SUCCESS;
		},
		viewIsLoading() {
			return this.current_view === VIEW_LOADING;
		},
		viewIsError() {
			return this.current_view === VIEW_ERROR;
		},
		setViewToConfirmBooking() {
			this.current_view = VIEW_CONFIRM_BOOKING;
		},
		resetErrorState() {
			this.error_messages = [];
			this.current_view = VIEW_INITIAL;
		},
		updateBookingDate( date ) {
			this.booking_date = date[0];
		},
		getLocationNameFromId( location_id ) {
			return this.locations.find(
				location => location.id.toString() === location_id.toString()
			).title;
		},
		getTimeslotRangeFromDate( time ) {
			const start_time = moment( time );
			const end_time = moment( start_time ).add(
				this.toolClassTimeslotLength,
				'minutes'
			);
			return `${start_time.format( 'HH:mm' )} — ${end_time.format( 'HH:mm' )}`;
		},
		updateCalendarEnabledDates( year_month_data ) {
			this.booking_year = year_month_data.year.toString();
			this.booking_month = year_month_data.month.toString();
			this.refreshDaysWithBookableToolInstances();
		},
	},
};

</script>
