import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "paginated-table" }
const _hoisted_2 = { class: "paginated-table__table" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "paginated-table__checkbox" }
const _hoisted_5 = {
  key: 0,
  class: "paginated-table__empty-message"
}
const _hoisted_6 = ["colspan"]
const _hoisted_7 = {
  key: 0,
  class: "paginated-table__selectable"
}
const _hoisted_8 = { class: "paginated-table__checkbox" }
const _hoisted_9 = ["data-label"]
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "paginated-table__loading-spinner"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 1,
  class: "paginated-table__detail-text"
}
const _hoisted_15 = { key: 2 }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { key: 3 }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { key: 0 }
const _hoisted_20 = {
  key: 1,
  class: "paginated-table__detail-text"
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { key: 5 }
const _hoisted_23 = { key: 6 }
const _hoisted_24 = { key: 7 }
const _hoisted_25 = { class: "paginated-table__checkbox" }
const _hoisted_26 = { key: 8 }
const _hoisted_27 = { class: "paginated-table__checkbox-and-text-with-detail" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = {
  key: 1,
  class: "paginated-table__detail-text"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_InputComponent = _resolveComponent("InputComponent")
  const _component_PaginationComponent = _resolveComponent("PaginationComponent")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            ($props.selectable)
              ? (_openBlock(), _createElementBlock("th", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_InputComponent, {
                      "model-value": $options.all_selected,
                      "input-id": `${$props.idPrefix}-selection-row-all`,
                      type: "checkbox",
                      "hide-label": true,
                      "no-margin": true,
                      "onUpdate:modelValue": $options.toggleSelectAll
                    }, null, 8 /* PROPS */, ["model-value", "input-id", "onUpdate:modelValue"])
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column, index) => {
              return (_openBlock(), _createElementBlock("th", { key: index }, _toDisplayString(column.label), 1 /* TEXT */))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("tbody", null, [
          ($options.paginatedData.length === 0)
            ? (_openBlock(), _createElementBlock("tr", _hoisted_5, [
                _createElementVNode("td", {
                  colspan: $props.columns.length + ( $props.selectable ? 1 : 0 )
                }, " No data to display. ", 8 /* PROPS */, _hoisted_6)
              ]))
            : _createCommentVNode("v-if", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paginatedData, (row) => {
            return (_openBlock(), _createElementBlock("tr", {
              key: row.index,
              class: "paginated-table__content"
            }, [
              ($props.selectable)
                ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_InputComponent, {
                        "input-id": `${$props.idPrefix}-selection-row-${ row.index }`,
                        "model-value": $props.selectedIndexes.indexOf( row.index ) > -1,
                        type: "checkbox",
                        "hide-label": true,
                        "no-margin": true,
                        "onUpdate:modelValue": is_checked => $options.updateCheckedIndexes( row.index, is_checked )
                      }, null, 8 /* PROPS */, ["input-id", "model-value", "onUpdate:modelValue"])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.data, (value, index_inner) => {
                return (_openBlock(), _createElementBlock("td", {
                  key: index_inner,
                  class: _normalizeClass({
							'paginated-table__action-td': $options.columnIsActionButton( index_inner ),
							'paginated-table__action-td--floating': $options.columnIsActionButtonFloating( index_inner )
						}),
                  "data-label": $props.columns[index_inner].label
                }, [
                  ($options.columnIsText( index_inner ))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(value.visible), 1 /* TEXT */))
                    : _createCommentVNode("v-if", true),
                  ($options.columnIsTextWithDetail( index_inner ))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        (value.pending)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(value.visible.text) + " ", 1 /* TEXT */),
                              (value.visible.detail)
                                ? (_openBlock(), _createElementBlock("br", _hoisted_13))
                                : _createCommentVNode("v-if", true),
                              (value.visible.detail)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(value.visible.detail), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true)
                            ], 64 /* STABLE_FRAGMENT */))
                      ]))
                    : ($options.columnIsActionLink( index_inner ))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createElementVNode("button", {
                            class: "paginated-table__action-link",
                            onClick: $event => (_ctx.$emit($props.columns[index_inner].action_event, row.data))
                          }, _toDisplayString(value.visible), 9 /* TEXT, PROPS */, _hoisted_16)
                        ]))
                      : _createCommentVNode("v-if", true),
                  ($options.columnIsActionLinkWithDetail( index_inner ))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("button", {
                          class: "paginated-table__action-link",
                          onClick: $event => (_ctx.$emit($props.columns[index_inner].action_event, row.data))
                        }, [
                          _createTextVNode(_toDisplayString(value.visible.text) + " ", 1 /* TEXT */),
                          (value.visible.detail)
                            ? (_openBlock(), _createElementBlock("br", _hoisted_19))
                            : _createCommentVNode("v-if", true),
                          (value.visible.detail)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(value.visible.detail), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ], 8 /* PROPS */, _hoisted_18)
                      ]))
                    : ($options.columnIsActionButton( index_inner ) || $options.columnIsActionButtonFloating( index_inner ))
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 4,
                          class: _normalizeClass({ 'flex flex--justify-end': index_inner === row.data.length - 1 })
                        }, [
                          _createElementVNode("button", {
                            class: _normalizeClass($options.getActionButtonClass( index_inner )),
                            onClick: $event => (_ctx.$emit($props.columns[index_inner].action_event, row.data))
                          }, _toDisplayString($props.columns[index_inner].button_label), 11 /* TEXT, CLASS, PROPS */, _hoisted_21)
                        ], 2 /* CLASS */))
                      : ($options.columnIsStatus( index_inner ))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["paginated-table__status", {
									'paginated-table__status--on': value.filterable.values.includes( $props.columns[index_inner].statuses.on ),
									'paginated-table__status--off': value.filterable.values.includes( $props.columns[index_inner].statuses.off ),
								}])
                            }, _toDisplayString(value.visible), 3 /* TEXT, CLASS */)
                          ]))
                        : ($options.columnIsBadge( index_inner ))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                              _createElementVNode("div", {
                                class: _normalizeClass(["paginated-table__badge", {
									'paginated-table__badge--green': value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.green ),
									'paginated-table__badge--yellow': value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.yellow ),
									'paginated-table__badge--red': value.filterable.values.includes( $props.columns[index_inner].values && $props.columns[index_inner].values.red ),
								}])
                              }, _toDisplayString(value.visible), 3 /* TEXT, CLASS */)
                            ]))
                          : ($options.columnIsActionCheckbox( index_inner ))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                _createElementVNode("div", _hoisted_25, [
                                  _createVNode(_component_InputComponent, {
                                    "input-id": `${$props.idPrefix}-action-checkbox-row-${ row.index }-col-${ index_inner }`,
                                    "model-value": value.checked,
                                    type: "checkbox",
                                    "hide-label": true,
                                    "no-margin": true,
                                    "onUpdate:modelValue": value => _ctx.$emit( $props.columns[index_inner].action_event, { row: row.data, value } )
                                  }, null, 8 /* PROPS */, ["input-id", "model-value", "onUpdate:modelValue"])
                                ])
                              ]))
                            : ($options.columnIsActionCheckboxAndTextWithDetail( index_inner ))
                              ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                  _createElementVNode("div", _hoisted_27, [
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_InputComponent, {
                                        "input-id": `${$props.idPrefix}-action-checkbox-row-${ row.index }-col-${ index_inner }`,
                                        "model-value": value.checked,
                                        type: "checkbox",
                                        "hide-label": true,
                                        "no-margin": true,
                                        "onUpdate:modelValue": value => _ctx.$emit( $props.columns[index_inner].action_event, { row: row.data, value } )
                                      }, null, 8 /* PROPS */, ["input-id", "model-value", "onUpdate:modelValue"])
                                    ]),
                                    (value.visible)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                          _createTextVNode(_toDisplayString(value.visible.text) + " ", 1 /* TEXT */),
                                          (value.visible.detail)
                                            ? (_openBlock(), _createElementBlock("br", _hoisted_29))
                                            : _createCommentVNode("v-if", true),
                                          (value.visible.detail)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(value.visible.detail), 1 /* TEXT */))
                                            : _createCommentVNode("v-if", true)
                                        ]))
                                      : _createCommentVNode("v-if", true)
                                  ])
                                ]))
                              : _createCommentVNode("v-if", true)
                ], 10 /* CLASS, PROPS */, _hoisted_9))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ])
    ]),
    ($options.pageCount > 1)
      ? (_openBlock(), _createBlock(_component_PaginationComponent, {
          key: 0,
          "page-count": $options.pageCount,
          "current-page": $data.current_page,
          onPageDecrement: $options.decrementPage,
          onPageIncrement: $options.incrementPage,
          onPageChange: $options.changePage
        }, null, 8 /* PROPS */, ["page-count", "current-page", "onPageDecrement", "onPageIncrement", "onPageChange"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}