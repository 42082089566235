
<template>
	<LoadingStateComponent
		:mode="loading_state"
		:full-window-height="true"
	>
		<HeaderComponent
			:heading="page_title"
			:breadcrumbs="breadcrumbs"
		>
			<template #actions>
				<ButtonComponent
					label="Duplicate announcement"
					icon="duplicate"
					@click="goToAnnouncementDuplicate"
				/>
				<ButtonComponent
					label="Delete announcement"
					type="danger"
					icon="delete"
					@click="show_delete_modal = true"
				/>
			</template>
		</HeaderComponent>
		<div class="page-content page-content--form">
			<InputComponent
				v-model="model_data.title"
				input-id="title"
				type="text"
				label="Brief summary"
				:errors="model_errors.title"
			/>
			<InputComponent
				v-model="model_data.message"
				label="Message"
				type="textarea"
				input-id="message"
				:rows="10"
				:errors="model_errors.message"
				:show-textarea-formatting-guide="true"
			/>
			<InputComponent
				v-model="has_expiry_date"
				label="This announcement has an expiry date"
				type="checkbox"
				input-id="has_expiry_date"
			/>
			<div
				v-if="has_expiry_date"
				class="flex flex--wrap grid"
			>
				<div class="grid__item a6-12">
					<InputComponent
						v-model="model_data.expiry_date"
						label="Expiry date"
						type="date"
						input-id="expiry_date"
						:errors="model_errors.expiryDate"
					/>
				</div>
				<div class="grid__item a6-12">
					<InputComponent
						v-model="model_data.expiry_time"
						label="Expiry time"
						type="time"
						input-id="expiry_time"
					/>
				</div>
			</div>
			<div class="flex flex--wrap grid">
				<div class="grid__item a6-12">
					<InputComponent
						v-model="model_data.more_info_url"
						label="More info URL"
						sub-label="Optional"
						type="text"
						input-id="more_info_url"
						:errors="model_errors.more_info_url"
					/>
				</div>
				<div class="grid__item a6-12">
					<InputComponent
						v-model="model_data.more_info_button_label"
						label="More info button label"
						sub-label="Optional, leave blank to show ‘Read more’"
						type="text"
						input-id="more_info_button_label"
						:errors="model_errors.more_info_button_label"
					/>
				</div>
			</div>

			<div class="flex flex--wrap grid grid--small">
				<div class="grid__item">
					<ButtonComponent
						label="Save changes"
						@click="saveAnnouncementData"
					/>
				</div>
				<div class="grid__item">
					<ButtonComponent
						label="Cancel"
						type="outline"
						@click="$router.push( { name: 'announcements__all_announcements' } )"
					/>
				</div>
			</div>
		</div>
	</LoadingStateComponent>
	<ModalComponent
		heading="Delete announcement"
		:show="show_delete_modal"
		@close-modal="show_delete_modal = false"
	>
		<p class="p">
			Are you sure you want to delete this announcement? After confirming below the data for this announcement will be permanently removed and this action cannot be undone.
		</p>
		<div class="flex flex--gap-small">
			<ButtonComponent
				label="Delete"
				type="danger"
				icon="delete"
				:padded="true"
				@click="deleteAnnouncement"
			/>
			<ButtonComponent
				label="Cancel"
				type="outline"
				:padded="true"
				@click="show_delete_modal = false"
			/>
		</div>
	</ModalComponent>
</template>

<script>

import LoadingStateComponent from '../../components/LoadingStateComponent.vue';
import InputComponent from '../../components/InputComponent.vue';
import ButtonComponent from '../../components/ButtonComponent.vue';
import HeaderComponent from '../../components/HeaderComponent.vue';
import ModalComponent from '../../components/ModalComponent.vue';

import gql_query_announcement from '../../graphql/query/Announcement.gql';
import gql_mutation_delete_entry from '../../graphql/mutation/DeleteEntry.gql';

import {
	formatDate,
	formatTime,
	scrollFirstErrorIntoView,
} from '../../../../helpers.js';

import {
	LOADING_STATE_NONE,
	LOADING_STATE_INITIAL,
	LOADING_STATE_OVERLAY,
	CONTENT_MODE_ADD,
	CONTENT_MODE_EDIT,
	CONTENT_MODE_DUPLICATE,
} from '../../../../constants.js';

export default {
	components: {
		LoadingStateComponent,
		InputComponent,
		ButtonComponent,
		HeaderComponent,
		ModalComponent
	},
	data() {
		return {
			loading_state: LOADING_STATE_INITIAL,
			model_data: {},
			model_errors: {},

			has_expiry_date: false,

			show_delete_modal: false,
		};
	},
	computed: {
		page_title() {
			if (
				this.content_mode === CONTENT_MODE_ADD
				&& (
					Object.keys( this.model_data ).length === 0
					|| this.model_data.title === undefined
					|| this.model_data.title === ''
				)
			) {
				return 'New announcement';
			}
			if ( this.content_mode === CONTENT_MODE_DUPLICATE ) {
				return this.model_data.title + ' (duplicating)';
			}
			return this.model_data.title;
		},
		breadcrumbs() {
			if ( this.loading ) {
				return [];
			}
			return [
				{ label: 'Announcements' },
				{ label: this.page_title },
			];
		},
		content_mode() {
			switch ( this.$route.name ) {
				case 'announcements__add_announcement':
					return CONTENT_MODE_ADD;
				case 'announcements__duplicate_announcement':
					return CONTENT_MODE_DUPLICATE;
				default:
					return CONTENT_MODE_EDIT;
			}
		},
	},
	mounted() {
		if ( this.content_mode === CONTENT_MODE_ADD ) {
			this.loading_state = LOADING_STATE_NONE;
			return;
		}
		this.$craftGraphqlApiClient.query(
			gql_query_announcement,
			{ id: this.$route.params.id }
		).then( ( response ) => {
			this.model_data = this.convertAnnouncementData( response.data.entry );
			this.loading_state = LOADING_STATE_NONE;
		} );
	},
	methods: {
		convertAnnouncementData( data ) {
			if ( data.expiryDate ) {
				this.has_expiry_date = true;
				const expiry_date = new Date( data.expiryDate );
				data.expiry_date = [formatDate( expiry_date )];
				data.expiry_time = formatTime( expiry_date );
			} else {
				this.has_expiry_date = false;
				data.expiry_date = [formatDate( new Date() )];
				data.expiry_time = '00:00';
			}
			return data;
		},
		async saveAnnouncementData() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.model_errors = {};

			const expiry_datetime = this.has_expiry_date
				&& this.model_data.expiry_date
				&& this.model_data.expiry_date.length > 0
				? {
					date: this.model_data.expiry_date[0],
					time: this.model_data.expiry_time.length ? this.model_data.expiry_time : null,
					timezone: 'Europe/London',
				}
				: {
					date: '',
					time: '',
					timezone: 'Europe/London',
				}
			;

			this.$craftActionApiClient.query(
				'entries/save-entry',
				{
					entryId: this.content_mode === CONTENT_MODE_EDIT ? this.model_data.id : null,
					sectionId: this.$craftSectionData.getSectionId( 'announcements' ),
					title: this.model_data.title,
					expiryDate: expiry_datetime,
					fields: {
						message: this.model_data.message,
						more_info_url: this.model_data.more_info_url,
						more_info_button_label: this.model_data.more_info_button_label,
					},
				}
			).then( ( response ) => {
				this.$messages.addNotice( 'Saved.' );
				if ( this.content_mode === CONTENT_MODE_EDIT ) {
					this.$router.push( {
						name: 'announcements__all_announcements',
					} );
					return;
				}
				// Unlike GraphQL, the regular action API doesn't return the
				// saved user back to us, so let's query it again.
				this.$craftGraphqlApiClient.query(
					gql_query_announcement,
					{ id: response.id }
				).then( ( response ) => {
					this.model_data = this.convertAnnouncementData( response.data.entry );
					this.$router.push( {
						name: 'announcements__edit_announcement',
						params: {
							id: this.model_data.id,
						},
					} );
					this.loading_state = LOADING_STATE_NONE;
				} );
			} ).catch( error => {
				if ( 400 !== error.response.status ) {
					throw error;
				}
				this.$messages.addError( 'Save failed.' );
				this.model_errors = error.response.data.errors;
				scrollFirstErrorIntoView( error.response.data.errors );
				this.loading_state = LOADING_STATE_NONE;
			} );
		},
		deleteAnnouncement() {
			this.loading_state = LOADING_STATE_OVERLAY;
			this.show_delete_modal = false;
			this.$craftGraphqlApiClient.query(
				gql_mutation_delete_entry,
				{
					id: parseInt( this.model_data.id, 10 ),
				}
			).then( () => {
				this.$router.push( {
					name: 'announcements__all_announcements',
				} );
			} );
		},
		goToAnnouncementDuplicate() {
			this.$router.push( {
				name: 'announcements__duplicate_announcement',
				params: {
					id: this.model_data.id,
				},
			} );
		},
	},
};

</script>
