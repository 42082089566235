
import {
	TASK_SCHEDULE_TYPE_DAILY,
	TASK_SCHEDULE_TYPE_WEEKLY,
	TASK_SCHEDULE_TYPE_YEARLY,
	TASK_SCHEDULE_TYPE_ONE_OFF,
	TASK_SCHEDULE_TYPE_SCHEDULED,
} from './constants.js';

import moment from 'moment';

export function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}

export function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}

export function ucFirst( string ) {
	if ( !string ) {
		return '';
	}
	return string[0].toUpperCase() + string.slice( 1 );
}

export function debounce( func, delay = 500 ) {
	let timer;
	return ( ...args ) => {
		clearTimeout( timer );
		timer = setTimeout( () => {
			func.apply( this, args );
		}, delay );
	};
}

export function convertCraftEntriesToSelectOptions(
	data,
	label_callback = ( e ) => e.title
) {
	return data.reduce(
		( previous, current ) => {
			previous.push( {
				value: current.id,
				label: label_callback( current ),
			} );
			if ( current.children ) {
				previous.push( ...current.children.reduce(
					( previous_inner, current_inner ) => {
						previous_inner.push( {
							value: current_inner.id,
							label: label_callback( current ) + ' - ' + label_callback( current_inner ),
						} );
						return previous_inner;
					},
					[]
				) );
			}
			return previous;
		},
		[]
	);
}

// is_selectable must be a function that returns a boolean.
export function convertCraftEntriesToSelectOptgroups(
	data,
	is_selectable,
	label_callback = ( e ) => e.title
) {
	return data.reduce(
		( previous, current ) => {
			const item = {
				label: label_callback( current ),
			};
			if ( is_selectable( current ) ) {
				item.value = current.id;
			}
			if ( current.children ) {
				item.children = current.children.reduce(
					( previous_inner, current_inner ) => {
						previous_inner.push( {
							value: current_inner.id,
							label: label_callback( current_inner ),
						} );
						return previous_inner;
					},
					[]
				);
			}
			previous.push( item );
			return previous;
		},
		[]
	);
}

// e.g. Jan 18th, 2022
export function formatDateHuman( date ) {
	const month = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec'
	][date.getMonth()];
	return `${month} ${date.getDate()}, ${date.getFullYear()}`;
}

// e.g. Jan 18th, 2022 • 02:45
export function formatDatetimeHuman( date ) {
	return `${formatDateHuman( date )} • ${ formatTime( date )}`;
}

// e.g. 2023-01-01
export function formatDate( date ) {
	const month = date.getMonth() + 1;
	const m_zero_padding = month < 10 ? '0' : '';
	const d_zero_padding = date.getDate() < 10 ? '0' : '';
	return `${date.getFullYear()}-${m_zero_padding}${month}-${d_zero_padding}${date.getDate()}`;
}

// e.g. 02:45
export function formatTime( date, include_seconds = false ) {
	const h_zero_padding = date.getHours() < 10 ? '0' : '';
	const m_zero_padding = date.getMinutes() < 10 ? '0' : '';
	if ( include_seconds ) {
		const s_zero_padding = date.getSeconds() < 10 ? '0' : '';
		return `${h_zero_padding}${date.getHours()}:${m_zero_padding}${date.getMinutes()}:${s_zero_padding}${date.getSeconds()}`;
	}
	return `${h_zero_padding}${date.getHours()}:${m_zero_padding}${date.getMinutes()}`;
}

// e.g. 2022-01-18 02:45:00
export function formatDatetime( date ) {
	const datef = formatDate( date );
	const time = formatTime( date, true );
	return `${datef} ${time}`;
}

// e.g. 10:00-12:00
export function formatTimeslot( begins, ends ) {
	return formatTime( begins ) + '-' + formatTime( ends );
}

export function getDateAtMidnight( date ) {
	date.setHours( 0 );
	date.setMinutes( 0 );
	date.setSeconds( 0 );
	return date;
}

export const months_select_options = [
	{ value: '01', label: 'January' },
	{ value: '02', label: 'February' },
	{ value: '03', label: 'March' },
	{ value: '04', label: 'April' },
	{ value: '05', label: 'May' },
	{ value: '06', label: 'June' },
	{ value: '07', label: 'July' },
	{ value: '08', label: 'August' },
	{ value: '09', label: 'September' },
	{ value: '10', label: 'October' },
	{ value: '11', label: 'November' },
	{ value: '12', label: 'December' },
];

export function getYearsSelectOptions( years_past = 0, years_future = 0 ) {
	const this_year = ( new Date() ).getFullYear();
	const options = [];
	for ( let i = ( this_year - years_past ); i <= ( this_year + years_future ); i++ ) {
		const year = i.toString();
		options.push( { value: year, label: year } );
	}
	return options;
}

export function formatAuditLogMessage( legacy_message, action, metadata ) {
	if ( legacy_message ) {
		return legacy_message;
	}
	switch ( action ) {
		case 'LoggedIn':
			return 'Logged in';

		case 'LoggedOut':
			return 'Logged out';

		case 'Registered':
			return 'Registered account';

		case 'Edited':
			return 'Edited profile';

		case 'VerifiedEmail':
			return 'Verified email';

		case 'VerifiedSms':
			return 'Verified mobile phone no.';

		case 'DeletedAccount':
			return 'Deleted account';

		case 'ReceivedEmail':
			return `Received email with subject "${metadata.subject}"`;

		case 'ReceivedSms':
			return `Received SMS "${metadata.message}"`;

		case 'CompletedInduction':
			return 'Completed induction';

		case 'RevokedInduction':
			return 'Had induction revoked';

		case 'GrantedToolAccess':
			return `Had tool access granted for tool class "${metadata.tool_class_name}" (${metadata.tool_class_id})`;

		case 'RevokedToolAccess':
			return `Had tool access revoked for tool class "${metadata.tool_class_name}" (${metadata.tool_class_id})`;

		case 'SignedMemberAgreement':
			return 'Signed member agreement';

		case 'JoinedWaitlist':
			return `Joined waiting list for "${metadata.activity_title}" (${metadata.activity_id})`;

		case 'BookedActivity':
			return `Booked activity "${metadata.activity_title}" (${metadata.activity_id})`;

		case 'BookedTool':
			return `Booked tool "${metadata.tool_title}" (${metadata.tool_id})`;

		case 'CancelledActivityBooking':
			return `Cancelled activity booking "${metadata.activity_title}" (${metadata.activity_id})`;

		case 'CancelledToolBooking':
			return `Cancelled tool booking "${metadata.tool_title}" (${metadata.tool_id})`;

		case 'GivenWarning':
			return `Received a warning for reason "${metadata.reason}"`;

		case 'HadWarningRemoved':
			return `Had a warning removed (warning reason "${metadata.reason}")`;

		case 'HadWarningsReset':
			return 'Had warnings reset';
	}
	return `Unknown log type: ${action}`;
}

export function scrollFirstErrorIntoView( errors ) {
	const field_ids = Object.keys( errors );
	if ( field_ids.length === 0 ) {
		return;
	}
	const first_field = document.getElementById( field_ids[0] );
	if ( !first_field ) {
		return;
	}
	first_field.scrollIntoView( {
		block: 'center',
		inline: 'center',
	} );
}

export function getFileAsBase64( file ) {
	return new Promise( ( resolve, reject ) => {
		const reader = new FileReader();
		reader.addEventListener( 'load', ( event ) => {
			resolve( event.target.result );
		} );
		reader.addEventListener( 'error', () => {
			reject();
		} );
		reader.readAsDataURL( file );
	} );
}

export function setupShareLinks( selector = '.js-social-share' ) {
	const btns = document.querySelectorAll( selector );
	if ( !btns[0] ) {
		return;
	}

	for ( let i = 0; i < btns.length; i++ ) {
		btns[i].addEventListener( 'click', handleSocialShare( btns[i] ) );
	}

	function handleSocialShare( btn ) {
		return ( e ) => {
			e.preventDefault();
			popUpWindow( btn.href );
		};
	}
	function popUpWindow( url ) {
		const popup_width = 550;
		const popup_height = 420;
		const x = ( window.screen.width / 2 ) - ( popup_width / 2 );
		const y = ( window.screen.height / 2 ) - ( popup_height / 2 ) - 50;
		const new_window = window.open( url, '', 'dependent=1,height=' + popup_height.toString() + ',width=' + popup_width.toString() + ',left=' + x.toString() + ',top=' + y.toString() + ',resizable=0,status=0' );
		if ( window.focus ) {
			new_window.focus();
		}
		return false;
	}
}

export function getExportUrl( noun, from = null, to = null ) {
	if ( null === from || null === to ) {
		return `/manage/export/${noun}`;
	}
	const url_params = new URLSearchParams( [
		['from', moment( from ).format( 'YYYY-MM-DD' )],
		['to', moment( to ).format( 'YYYY-MM-DD' )],
	] ).toString();
	return `/manage/export/${noun}?${url_params}`;
}

export function getTaskScheduleTypeName( task_schedule_type ) {
	switch ( task_schedule_type ) {
		case TASK_SCHEDULE_TYPE_DAILY:
			return 'Daily';
		case TASK_SCHEDULE_TYPE_WEEKLY:
			return 'Weekly';
		case TASK_SCHEDULE_TYPE_YEARLY:
			return 'Yearly';
		case TASK_SCHEDULE_TYPE_ONE_OFF:
			return 'One-off';
		case TASK_SCHEDULE_TYPE_SCHEDULED:
			return 'Scheduled';
		default:
			return '';
	}
}

export function buildThresholdList( steps ) {
	const thresholds = [];

	for ( let i = 1.0; i <= steps; i++ ) {
		const ratio = i / steps;
		thresholds.push( ratio );
	}

	thresholds.push( 0 );
	return thresholds;
}
