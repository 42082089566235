
import { createApp, markRaw } from 'vue';
import App from './App.vue';
import { createPinia } from 'pinia';
import craftGraphqlApiClient from '../shared/plugins/CraftGraphqlApiClient.js';
import craftActionApiClient from '../shared/plugins/CraftActionApiClient.js';

const root = document.getElementById( 'tool-booking-widget' );
// Might not be on a booking page.
if ( root ) {
	const app = createApp( App, {
		toolClassId: root.dataset.toolClassId,
		toolClassTitle: root.dataset.toolClassTitle,
		toolClassTimeslotLength: parseInt( root.dataset.toolClassTimeslotLength, 10 ),
		locations: JSON.parse( root.dataset.locations ),
	} );

	app.use( craftGraphqlApiClient, {
		base_url: '/api',
	} );
	app.use( craftActionApiClient );

	const pinia = createPinia();
	pinia.use( ( context ) => {
		context.store.craftGraphqlApiClient = markRaw(
			context.app.config.globalProperties.$craftGraphqlApiClient
		);
		//context.store.craftActionApiClient = markRaw(
		//	context.app.config.globalProperties.$craftActionApiClient
		//);
	} );

	app.use( pinia );

	app.mount( root );
}
